<template>
  <div class="q-pa-md">
    <q-dialog ref="uploadFilePopup" v-model="isUploadPopupVisible" persistent>
      <q-card>
        <div class="q-pa-sm">
          <div class="text-h5">Select your file:</div>
          <q-select
            v-model="selectedCategory"
            :options="categoryOptions"
            label="Category"
          />
          <q-select
            v-model="selectedStudy"
            :options="studyOptions"
            label="Study"
          />
          <q-select
            v-model="selectedGeographic"
            :options="geographicScope"
            label="Geographic Scope"
          />
          <q-select
            v-if="selectedGeographic === 'Country'"
            v-model="selectedCountry"
            :options="countryOptions"
            label="Target countries"
            input-debounce="0"
            behavior="dialog"
            emit-value
          >
            <template v-slot:append>
              <q-icon name="search" class="cursor-pointer" />
            </template>
          </q-select>
          <q-select
            v-else-if="selectedGeographic === 'Region'"
            v-model="selectedRegion"
            :options="regionOptions"
            label="targetRegion"
          />

          <div class="q-pa-sm file-upload">
            <input
              type="file"
              @change="handleFileChange"
              ref="fileInput"
              style="display: none"
            />
            <q-btn label="Add File" @click="$refs.fileInput.click()" />
            <span>{{ selectedFileName || ' No file selected' }}</span>
          </div>
          <div class="column items-end">
            <q-btn
              class="q-pa-sm col"
              color="primary"
              size="15px"
              label="Upload"
              @click="saveDataAndClosePopup"
            />
          </div>
        </div>
      </q-card>
    </q-dialog>
    <GipamTable
      :columns="[
        {
          name: 'name',
          label: 'Name',
          align: 'left',
          field: 'name',
        },
        {
          name: 'category',
          label: 'Category',
          align: 'left',
          field: 'category',
        },
        {
          name: 'study',
          label: 'Study',
          align: 'left',
          field: 'study',
        },
        {
          name: 'geographicScope',
          label: 'Geographic Scope',
          align: 'left',
          field: 'geographicScope',
        },
        {
          name: 'region',
          label: 'Region',
          align: 'left',
          field: 'region',
        },
        {
          name: 'country',
          label: 'Country',
          align: 'left',
          field: 'country',
        },
      ]"
      :rows="documentList"
      :rowsPerPage="rowsPerPage"
      title="Your documents"
    >
    </GipamTable>

    <p></p>
    <q-btn
      @click="uploadFilePopup"
      label="Upload"
      color="primary"
      class="q-m-md"
    />
  </div>
</template>

<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { fetchStudyOptions } from '@/service/DocumentUploadService.ts'
import axios from 'axios'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'documentPage',
  components: {
    GipamTable,
  },
  setup() {
    const egpId = ref(null)
    const nameEgp = ref('')
    const selectedCategory = ref(null)
    const selectedStudy = ref(null)
    const selectedCountry = ref(null)
    const selectedRegion = ref(null)
    const selectedGeographic = ref(null)
    const categoryOptions = ref([])
    const countryOptions = ref([])
    const regionOptions = ref(['Europe', 'USA', 'Asia', 'Rest of the world'])
    const geographicScope = ref(['Country', 'Region', 'Global'])
    const isUploadPopupVisible = ref(false)
    const selectedRow = ref(null)
    const selectedFile = ref(null)
    const selectedFileName = ref('')
    const fetchedCategories = ref({})
    const route = useRoute()

    const loadCountryOptions = async () => {
      try {
        const response = await axios.get(`/egp/loadCountryOptions/`)
        countryOptions.value = response.data.map(
          (targetCountries) => targetCountries.en,
        )
      } catch (err) {
        console.error(err)
      }
    }

    const handleFileChange = (event) => {
      const file = event.target.files[0]
      if (file) {
        selectedFile.value = event.target.files[0]
        selectedFileName.value = file.name
      } else {
        selectedFile.value = null
        selectedFileName.value = 'No file selected'
      }
    }

    const uploadFilePopup = () => {
      isUploadPopupVisible.value = true
    }

    const saveDataAndClosePopup = async () => {
      try {
        const formData = new FormData()
        formData.append('file', selectedFile.value)
        formData.append('egpId', egpId.value)
        formData.append('geographicScope', selectedGeographic.value)
        formData.append('region', selectedRegion.value || '')
        formData.append('study', selectedStudy.value)
        formData.append('category', selectedCategory.value)
        formData.append(
          'filepath',
          '/' +
            egpId.value +
            '/' +
            selectedGeographic.value +
            '/' +
            selectedCountry.value +
            '/' +
            selectedCategory.value +
            '/' +
            selectedStudy.value,
        )
        console.log(formData)
        const response = await axios.post('template/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        console.log(response)
      } catch (error) {
        console.error('Error uploading file:', error)
      }
    }

    const studyOptions = computed(() => {
      if (selectedCategory.value) {
        const studiesForCategory = Object.entries(fetchedCategories.value)
          .filter(([, value]) => value.category === selectedCategory.value)
          .map(([key]) => key)
        return studiesForCategory
      }
      return []
    })

    watch(selectedGeographic, (newValue) => {
      if (newValue === 'Country') {
        selectedRegion.value = null
      } else if (newValue === 'Region') {
        selectedCountry.value = null
      } else {
        selectedCountry.value = null
        selectedRegion.value = null
      }
    })

    onMounted(async () => {
      egpId.value = route.query.egpId
      nameEgp.value = route.query.name
      loadCountryOptions()
      const fetchedData = await fetchStudyOptions(egpId.value)
      fetchedCategories.value = fetchedData
      categoryOptions.value = [
        ...new Set(Object.values(fetchedData).map((item) => item.category)),
      ]
      // change to /egp/getEgpDocuments/ when backend is ready
      const response = await axios.get(
        '/template/getEgpDocuments/' + egpId.value,
      )
      console.log(response)
    })

    return {
      egpId,
      nameEgp,
      selectedStudy,
      selectedCategory,
      selectedCountry,
      selectedRegion,
      selectedGeographic,
      categoryOptions,
      countryOptions,
      regionOptions,
      geographicScope,
      isUploadPopupVisible,
      selectedRow,
      selectedFile,
      selectedFileName,
      studyOptions,
      loadCountryOptions,
      handleFileChange,
      uploadFilePopup,
      saveDataAndClosePopup,
    }
  },
}
</script>
