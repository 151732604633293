<template>
  <div class="q-pa-md text-center bordered-container">
    <GipamTable
      v-if="rows.length > 0"
      :columns="columnNames"
      :rows="computedRows"
      :rowsPerPage="rowsPerPage"
      :wrap-cells="wrapCells"
      :rowTypes="computedRowTypes"
      :isMandatory="isMandatory"
      @option-selected="handleOptionSelected"
      @link-clicked="handleLinkClicked"
    >
    </GipamTable>
    <div v-else class="no-rows-message-container q-my-md">
      <span class="no-rows-message">
        {{
          'Thank you for completing the questionnaire! All responses have been successfully recorded, and there are no further questions or gaps'
        }}
      </span>
      <div class="q-pt-md"></div>
      <q-btn label="Go to monitoring" color="primary" @click="goToMonitoring" />
    </div>
    <SavePopup :show="showSavePopup" @close="showSavePopup = false" />
  </div>
</template>
<style>
.grey-row {
  background-color: grey;
}

.green-row {
  background-color: green;
}

.blue-row {
  background-color: blue;
}

.template-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.no-rows-message {
  font-size: 1.2rem;
  color: #555;
}

.no-rows-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bordered-container {
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
}
</style>
<script>
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import DataService from '@/service/DataService.ts'
import selfCheckService from '@/service/SelfCheckService.ts'
import SavePopup from './SavePopup.vue'

export default {
  name: 'selfCheck',
  components: {
    GipamTable,
    SavePopup,
  },
  data() {
    return {
      egpId: null,
      nameEgp: '',
      showSavePopup: false,
      wrapCells: true,
      selectedOptionRowIndex: [],
      selectedOptionColumnIndex: [],
      selectedOption: [],
      content: [],
      rows: [],
      columnNames: [],
      rowsPerPage: 0,
    }
  },
  computed: {
    computedRows() {
      console.log('computedRows:', this.rows)
      return this.rows.map((row) => ({
        ...row,
        link:
          row.answer === 'No' || row.answer === 'Unsure'
            ? 'Go to template'
            : '',
      }))
    },
    computedRowTypes() {
      return this.rows.reduce((rowTypes, row, rowIndex) => {
        rowTypes[rowIndex] = {
          question: 'static',
          answer: 'dropdown',
          link:
            row.answer === 'No' || row.answer === 'Unsure' ? 'link' : 'static',
        }
        return rowTypes
      }, {})
    },
    isMandatory() {
      return this.rows.reduce((acc, _, rowIndex) => {
        acc[rowIndex] = this.columnNames.reduce((obj, _, columnIndex) => {
          obj[columnIndex] = false
          return obj
        }, {})
        return acc
      }, {})
    },
  },
  async mounted() {
    this.nameEgp = this.$route.query.name
    await this.getSelfCheck(this.$route.query.egpId, this).then(async () => {
      await this.loadSavedAnswers(this.$route.query.egpId, this)
    })
    this.fillSelfCheckColumnNames()
  },
  methods: {
    handleOptionSelected(value, rowIndex) {
      // Update the row with the selected option value
      this.rows[rowIndex].answer = value
      this.saveSelfCheck()
    },

    goToMonitoring() {
      const egpId = this.$route.query.egpId
      this.$router.push(`/EgpMonitoring?egpId=${egpId}`)
    },

    saveSelfCheck() {
      selfCheckService.saveSelfCheck(this.$route.query.egpId, this)
    },
    async getSelfCheck(egpId, selfCheckData) {
      try {
        const response = await DataService.getSelfCheck(egpId)
        selfCheckData.tableData = response.data
        selfCheckData.rows = []

        selfCheckData.tableData.forEach((row, index) => {
          const rowData = {
            question: row.question,
            answer: '', // Initialize the answer property with an empty string or a default value
            link: '',
            id: row.id,
          }

          if (
            selfCheckData.selectedOption.includes('No') ||
            selfCheckData.selectedOption.includes('Unsure')
          ) {
            const noOptionIndex = selfCheckData.selectedOption.indexOf('No')
            const unsureOptionIndex =
              selfCheckData.selectedOption.indexOf('Unsure')
            if (
              selfCheckData.selectedOptionRowIndex[noOptionIndex] === index ||
              selfCheckData.selectedOptionRowIndex[unsureOptionIndex] === index
            ) {
              rowData.link = 'Go to link'
            }
          }

          selfCheckData.rows.push(rowData)
        })
      } catch (error) {
        console.error('Error loading data: ', error)
      }
    },
    async loadSavedAnswers(egpId, selfCheckData) {
      try {
        if (egpId) {
          egpId = selfCheckData.$route.query.egpId
        }
        let response = await DataService.loadSavedAnswers(egpId)
        const savedAnswers = response.data

        selfCheckData.rows = selfCheckData.rows.map((row) => {
          const savedAnswer = savedAnswers.find(
            (answer) => answer.frageId === row.id,
          )
          if (savedAnswer) {
            return {
              ...row,
              answer: savedAnswer.antwortId,
              link:
                savedAnswer.antwortId === 'No' ||
                savedAnswer.antwortId === 'Unsure'
                  ? 'Go to template'
                  : '',
            }
          }
          return row
        })
      } catch (error) {
        console.error('Fehler beim Laden der gespeicherten Antworten: ', error)
      }
    },
    handleLinkClicked(rowIndex) {
      this.saveSelfCheck()
      const selfCheckQuestionId = this.rows[rowIndex].id
      selfCheckService.showTemplateLink(
        selfCheckQuestionId,
        this.$route.query.egpId,
        this.$router,
      )
    },

    fillSelfCheckColumnNames() {
      const options = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
        { label: 'Unsure', value: 'Unsure' },
      ]
      this.columnNames = [
        {
          name: 'question',
          align: 'left',
          label: 'Question',
          field: 'question',
          type: 'static',
          isMandatory: false,
        },
        {
          name: 'answer',
          align: 'center',
          label: 'Your response',
          field: 'answer',
          type: 'dropdown',
          isMandatory: false,
          options,
        },
        {
          name: 'template',
          align: 'center',
          label: 'Template to be filled',
          field: 'link', // Use the 'link' field for the 'template' column
          type: 'link',
          isMandatory: false,
        },
      ]
    },
  },
}
</script>
