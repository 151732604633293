<template>
  <q-dialog v-model="templateDialog" full-width>
    <q-card>
      <q-card-section class="q-pt-md">
        <component
          :is="componentName"
          @save-event="saveTemplate"
          @cancel-event="cancelTemplate"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script src="./GipamTemplateDialogController.ts"></script>
