<template>
  <div class="editable-cell-container">
    <q-select
      v-model="selectedOption"
      :options="options"
      :modelValue="modelValue"
      :isMandatory="isMandatory"
      @update:model-value="handleModelValueUpdate"
      emit-value
      map-options
      new-value-mode="add-unique"
      :disable="showEditModal"
      style="width: max-content; max-width: 90vw; min-width: 200px"
    >
      <template v-slot:selected>
        <div class="row items-center">
          <div class="col-auto">
            <span
              v-if="selectedOption"
              :class="{ 'text-negative': isMandatory && isPlaceholder }"
              >{{ selectedOption }}</span
            >
            <span v-else class="text-negative">Select an option</span>
          </div>
          <div class="col-auto">
            <q-btn
              flat
              dense
              color="primary"
              icon="edit"
              @click.stop="openEditModal"
            />
          </div>
        </div>
      </template>
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>

    <q-dialog v-model="showEditModal">
      <q-card>
        <q-card-section>
          <q-input v-model="editedLabel" label="Edit Option" />
        </q-card-section>
        <q-card-actions>
          <q-btn color="primary" @click="saveEditedOption">Save</q-btn>
          <q-btn color="negative" @click="closeEditModal">Cancel</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, null],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMandatory: {
      type: Boolean,
    },
  },
  mounted() {
    // Check if this.modelValue is not null or undefined or an empty array
    if (
      this.modelValue !== null &&
      this.modelValue !== undefined &&
      this.modelValue.length > 0
    ) {
      // Split the value by '*' to get the parts
      const targetPopulationParts = this.modelValue.split('*')
      // Map over the parts and replace '###COMMA###' with ','
      const updatedTargetPopulationParts = targetPopulationParts.map((part) =>
        part.replace(/###COMMA###/g, ','),
      )
      const modelValueArray = updatedTargetPopulationParts
      this.selectedOption = modelValueArray.join(', ') // Set the selectedOption with the updated value

      // Create a Set to remove duplicates
      const uniqueOptions = new Set([
        ...this.options.map((option) => option.value),
        ...modelValueArray,
      ])

      // Convert the Set back to an array of objects
      const updatedOptions = Array.from(uniqueOptions).map((value) => ({
        label: value,
        value,
      }))
      this.$emit('update:options', updatedOptions)
    } else {
      this.selectedOption = null // Reset the selectedOption
    }
  },
  data() {
    return {
      selectedOption: null,
      showEditModal: false,
      editedLabel: '',
    }
  },
  watch: {
    modelValue(newValue) {
      if (
        this.modelValue !== null &&
        this.modelValue !== undefined &&
        this.modelValue.length > 0
      ) {
        this.selectedOption = newValue
        // Split the value by '*' to get the parts
        const targetPopulationParts = this.selectedOption.split('*')
        // Map over the parts and replace '###COMMA###' with ','
        const updatedTargetPopulationParts = targetPopulationParts.map((part) =>
          part.replace(/###COMMA###/g, ','),
        )
        const modelValueArray = updatedTargetPopulationParts
        if (modelValueArray.length > 0) {
          this.selectedOption = modelValueArray[0]
        }
      } else {
        this.selectedOption = null
      }
    },
  },
  computed: {
    isPlaceholder() {
      return this.selectedOption === null || this.selectedOption.length === 0
    },
  },
  methods: {
    handleModelValueUpdate(value) {
      this.$emit('update:modelValue', value)
    },
    openEditModal() {
      this.editedLabel = this.selectedOption
      this.showEditModal = true
    },
    saveEditedOption() {
      if (this.editedLabel) {
        const updatedValue = this.editedLabel
        this.handleModelValueUpdate(updatedValue)
        this.$emit('update:modelValue', updatedValue)
        this.selectedOption = updatedValue
        this.$emit('update:options', [
          ...this.options,
          { label: this.editedLabel, value: this.editedLabel },
        ])
      }
      this.closeEditModal()
    },
    closeEditModal() {
      this.showEditModal = false
      this.editedLabel = ''
    },
  },
}
</script>
