import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import VueJwtDecode from "vue-jwt-decode";

const store = createStore({
  state: {
      user: {      
        token: '',
        user: ''
      },
      loggedIn: false,
      leftDrawerOpen: true,
      egpId: null,
      templateId: null,
      frageId: null
  },
  mutations: {
    changeToken(state, newToken) {
      state.user.token = newToken;
    },
    setUser(state, user) {
      state.user = user;
    },
    changeEgpId(state, id) {
      state.egpId = id;
    },
    changeTemplateId(state, id) {
      state.templateId = id;
    },
    changeFrageId(state, id) {
      state.frageId = id;
    },
    setLoggedIn(state) {
      state.loggedIn = true;
    },
    setLoggedOut(state) {
      state.loggedIn = false;
      window.sessionStorage.clear();
    },
    changeLeftDrawerOpen(state) {
        state.leftDrawerOpen = !state.leftDrawerOpen;
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  getters: {
    getRole(state) {
      let result = "";

      // get token from localstorage
      if (state.user.token != '') {
        try {
          //decode token here and attach to the user object
          let decoded = VueJwtDecode.decode(state.user.token);
          result = decoded.role;
          
        } catch (error) {
          // return error in production env
          console.log(error, "error from decoding token");
        }
      }

      return result;
    },  
    getUsername(state) {
      return state.user.username;
    },
    getCurrentEgpId(state) {
      return state.egpId;
    },    
    getCurrentTemplateId(state) {
      return state.templateId;
    },  
    getCurrentFrageId(state) {
      return state.frageId;
    }
  }
});

export default store;