<template>
  <div class="editable-cell-container">
    <span class="cell-content">
      <template v-if="!isNameEgpValid && isMandatory">
        <div :label="`Name ${columnTitle}`" class="text-negative">
          Name {{ columnTitle }}
        </div>
      </template>
      {{ localContent }}
    </span>
    <q-popup-edit
      :modelValue="localContent"
      @update:modelValue="handleModelValueUpdate"
      :title="columnTitle"
      :isMandatory="isMandatory"
      buttons
      persistent
      v-slot="scope"
    >
      <q-input v-model="scope.value" dense autofocus />
    </q-popup-edit>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, null],
      default: null,
    },
    columnTitle: {
      type: String,
      required: true,
    },
    isMandatory: {
      type: Boolean,
    },
  },
  emits: ['valueUpdated'],
  data() {
    return {
      localContent: this.modelValue,
    }
  },
  watch: {
    modelValue(newValue) {
      this.localContent = newValue
    },
  },
  mounted() {
    if (this.localContent === 'N/A') {
      this.localContent = ''
    }
    this.handleModelValueUpdate(this.localContent)
  },
  computed: {
    isNameEgpValid() {
      if (this.localContent) {
        return this.localContent.trim() !== '' && this.isMandatory
      } else {
        return false
      }
    },
  },
  methods: {
    handleModelValueUpdate(newValue) {
      this.localContent = newValue
      this.$emit('update:modelValue', newValue)
    },
  },
}
</script>

<style scoped>
.editable-cell-container {
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  width: 150px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
