<template>
  <div
    class="input-container"
    style="width: max-content; max-width: 90vw; min-width: 200px"
  >
    <q-btn
      dense
      flat
      icon="remove"
      @click.prevent="decrementValue(stepSize)"
      class="input-button left-button"
    />
    <q-input
      v-model.number="displayValue"
      :isMandatory="isMandatory"
      @keydown.up="incrementValue(stepSize)"
      @keydown.down="decrementValue(stepSize)"
      class="input-field"
    >
    </q-input>
    <q-btn
      dense
      flat
      icon="add"
      @click.prevent="incrementValue(stepSize)"
      class="input-button right-button"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '0 weeks',
    },
    stepSize: {
      type: Number,
      default: 1,
    },
    isMandatory: {
      type: Boolean,
    },
  },
  data() {
    return {
      value: this.parseValue(this.modelValue),
    }
  },
  computed: {
    displayValue: {
      get() {
        return this.value + ' weeks'
      },
      set(newValue) {
        const parsedValue = parseInt(newValue)
        if (!isNaN(parsedValue)) {
          this.value = parsedValue
          this.$emit('update:modelValue', `${this.value} weeks`)
        }
      },
    },
  },
  watch: {
    modelValue(newValue) {
      this.value = this.parseValue(newValue)
    },
  },
  methods: {
    parseValue(valueString) {
      const [value, unit] = valueString.split(' ')
      return unit === 'weeks' ? parseInt(value) : 0
    },
    incrementValue(step) {
      this.value = Math.max(this.value + step, 0)
      this.$emit('update:modelValue', `${this.value} weeks`)
    },
    decrementValue(step) {
      this.value = Math.max(this.value - step, 0)
      this.$emit('update:modelValue', `${this.value} weeks`)
    },
  },
}
</script>

<style scoped>
.input-container {
  display: flex;
  align-items: center;
}

.input-field {
  flex: 1;
  margin: 0 8px;
  min-width: 150px; /* Adjust this value as needed */
}

.input-button {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.left-button {
  background-color: #f44336;
  color: white;
}

.right-button {
  background-color: #4caf50;
  color: white;
}
</style>
