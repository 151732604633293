<template>
  <div>
    <div class="q-pa-sm">
      <div class="q-gutter-sm text-primary">
        <div v-for="option in answerOptions" :key="option.value">
          <div class="row items-center q-gutter-md">
            <q-checkbox
              v-model="selectedAnswer"
              :val="option.value"
              :label="option.label"
              @update:model-value="handleAnswerChange"
            />
            <q-btn
              v-if="
                selectedAnswer.includes(option.value) &&
                option.value !== 50 &&
                option.isSelected
              "
              color="primary"
              label="Edit Template"
              @click="showTemplateDialog(option.value)"
              class="q-ml-md"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxQuestionCard',
  props: {
    question: {
      type: Object,
      required: true,
    },
    componentName: {
      type: String,
      required: false,
    },
  },
  computed: {
    questionLabel() {
      return `${this.question.lfdNr}: ${this.question.question}`
    },
    answerOptions() {
      return this.question.answers
        ? this.question.answers.map((answer) => ({
            label: answer.text,
            value: answer.id,
            isSelected: answer.isSelected,
          }))
        : []
    },
  },
  data() {
    return {
      selectedAnswer: [],
    }
  },
  mounted() {
    this.initSelectedAnswerIds()
  },
  methods: {
    handleAnswerChange(answerIds) {
      try {
        // Check if answerIds contains 50 or 66
        const contains50Or66 = answerIds.includes(50) || answerIds.includes(46)
        // If answerIds contains 50 or 66, deselect all other answers
        if (contains50Or66) {
          this.selectedAnswer = answerIds.filter((id) => id === 50 || id === 46)
        } else {
          this.selectedAnswer = answerIds
        }
        this.$emit('answer-change', this.selectedAnswer)
      } catch (error) {
        console.error('Error in handleAnswerChange method:', error)
      }
    },
    initSelectedAnswerIds() {
      const selectedAnswer = this.question.answers
        .filter((answer) => answer.isSelected)
        .map((answer) => answer.id)
      this.selectedAnswer = selectedAnswer
    },
    showTemplateDialog(answerId) {
      this.$emit('show-template-dialog', answerId)
    },
  },
}
</script>
