<template>
  <div class="text-primary text-h2">{{ category }}</div>
  <h3 class="text-primary text-h5">{{ label }}</h3>
</template>

<script>
export default {
  name: 'QuestionTitle',
  props: {
    category: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
}
</script>
