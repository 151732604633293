export default {
  props: {
    modelValue: Boolean,
    componentName: String,
  },
  emits: ['update:modelValue'],
  methods: {
    closeDialog() {
      this.$emit('update:modelValue', false)
    },
  },
}
