<template>
  <div class="q-pa-md" style="text-align: right">
    <q-btn rounded bordered style="border-color: #ff7500" @click="onSupport">
      <img src="@/assets/Short_logo.png" alt="Logo" style="height: 22px" />
      <div class="text-primary">Get help in developing this EGP</div>
    </q-btn>
  </div>
</template>

<script>
import store from '@/store.js'
export default {
  name: 'QuestionHeader',
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onSupport() {
      const username = store.state.user.username // get the username from the Vuex store
      const emailAddress = 'axel.dittmar@ipam-wismar.de'
      const subject = 'Technische Support-Anfrage'
      const message = `Technische Hilfe wird für Nutzer ${username} in ${this.category} benötigt.`

      const mailToLink = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`
      window.location.href = mailToLink
    },
  },
}
</script>
