// TemplateService.js
import { getTemplateByAnswerId, saveTemplate } from '@/service/Api.ts'
import store from '@/store.js'

export default {
  async getTemplateByAnswerId(answerId) {
    return await getTemplateByAnswerId(answerId)
  },

  async handleTemplateResponse(responseTemplate, egpId) {
    if (responseTemplate && responseTemplate !== '') {
      store.commit('changeEgpId', egpId)
      store.commit('changeTemplateId', responseTemplate.id)
      return responseTemplate
    }
    return null
  },

  async saveTemplateData(data) {
    var studyId = 0
    const egpId = store.getters.getCurrentEgpId
    const convertedAnswers = {}

    data.forEach((answer) => {
      const converted = {}

      for (const [key, value] of Object.entries(answer)) {
        if (key !== 'studyId') {
          // Skip the 'studyId' property
          if (Array.isArray(value)) {
            converted[key] = value.join('*') // Change the delimiter to '*'
          } else {
            converted[key] = value ? value.toString() : ''
          }
        }
      }

      if (answer.studyId) {
        studyId = answer.studyId // Assuming there's a 'studyId' property in each answer object
      } else {
        studyId -= 1
      }
      convertedAnswers[studyId] = converted
    })
    const dto = {
      epgId: egpId,
      templateId: store.getters.getCurrentTemplateId,
      answers: convertedAnswers,
    }
    try {
      return await saveTemplate(dto)
    } catch (err) {
      const errorMessage =
        err.response && err.response.data && err.response.data.message
          ? err.response.data.message
          : err.message || 'An unknown error occurred'
      throw new Error(`Failed to save template: ${errorMessage}`)
    }
  },
}
