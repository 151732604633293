// QuestionNavigationService.js
import store from '@/store.js'
import QuestionService from './QuestionService.ts'

export default {
  async getPreviousQuestions(currentQuestion) {
    const previousQuestionId =
      await QuestionService.getPreviousQuestion(currentQuestion)
    if (previousQuestionId !== null) {
      const egpId = store.getters.getCurrentEgpId
      return await QuestionService.getCurrentQuestion(previousQuestionId, egpId)
    }

    return null
  },
}
