<template>
  <q-dialog persistent>
    <q-card>
      <q-card-section class="row items-center">
        <span class="q-ml-sm">Your data has been saved successfully!</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          label="Close"
          color="primary"
          v-close-popup
          @click="closePopup"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    showSavePopup: {
      type: Boolean,
    },
  },
  methods: {
    closePopup() {
      this.$emit('close')
    },
  },
}
</script>
