import template1 from '@/components/templates/template1.vue'
import template10 from '@/components/templates/template10.vue'
import template11 from '@/components/templates/template11.vue'
import template12 from '@/components/templates/template12.vue'
import template13 from '@/components/templates/template13.vue'
import template14 from '@/components/templates/template14.vue'
import template15 from '@/components/templates/template15.vue'
import template16 from '@/components/templates/template16.vue'
import template17 from '@/components/templates/template17.vue'
import template18 from '@/components/templates/template18.vue'
import template19 from '@/components/templates/template19.vue'
import template2 from '@/components/templates/template2.vue'
import template20 from '@/components/templates/template20.vue'
import template21 from '@/components/templates/template21.vue'
import template22 from '@/components/templates/template22.vue'
import template23 from '@/components/templates/template23.vue'
import template24 from '@/components/templates/template24.vue'
import template25 from '@/components/templates/template25.vue'
import template26 from '@/components/templates/template26.vue'
import template3 from '@/components/templates/template3.vue'
import template4 from '@/components/templates/template4.vue'
import template5 from '@/components/templates/template5.vue'
import template6 from '@/components/templates/template6.vue'
import template7 from '@/components/templates/template7.vue'
import template8 from '@/components/templates/template8.vue'
import template9 from '@/components/templates/template9.vue'
export default {
  components: {
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
    template9,
    template10,
    template11,
    template12,
    template13,
    template14,
    template15,
    template16,
    template17,
    template18,
    template19,
    template20,
    template21,
    template22,
    template23,
    template24,
    template25,
    template26,
  },
  props: {
    componentName: '',
  },
  data() {
    return {
      templateDialog: false, // define errorDialog here
    }
  },
  methods: {
    saveTemplate(data) {
      this.$emit('save-template', data)
    },
    cancelTemplate() {
      this.$emit('cancel-template')
    },
  },
}
