<template>
  <GipamTable
    :tableTitle="'Treatment effect(s) on Mortality'"
    :columns="dynamicCells"
    :rowTypes="rowTypes"
    :rows="rows"
    :rowsPerPage="5"
    :isMandatory="isMandatory"
    @update-value="updateValue"
    @option-selected="handleOptionSelected"
    @delete-clicked="deleteRow"
  >
    <template v-slot:title-action>
      <q-btn color="primary" label="Add Row" @click="newLine" class="q-ml-md" />
    </template>
  </GipamTable>
  <ErrorDialog
    v-model="errorDialog"
    :message="errorMessage"
    @close-dialog="closeErrorDialog"
  />
  <div class="column">
    <div class="col"></div>
    <div class="col self-end">
      <q-btn color="primary" class="q-mt-md q-mr-sm" @click="this.save"
        >Save</q-btn
      >
      <q-btn color="primary" class="q-mt-md" @click="this.cancel">Cancel</q-btn>
    </div>
  </div>
</template>
<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamMandatoryErrorDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { getNextStudyId } from '@/service/Api.ts'
import store from '@/store.js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  components: {
    GipamTable,
    ErrorDialog,
  },
  name: 'Template_3',
  emits: ['save-event', 'cancel-event'],
  props: {
    msg: String,
  },

  data() {
    return {
      errorDialog: false,
      errorMessage:
        'Study population(s); incl. sub-populations, Standard of Care, Mortality Benefit',
      targetPopulationOptions: [],
      standardOfCareOptions: [],
      nextStudyId: '1',
      rows: [],
      columns: [
        {
          name: 'delete',
          align: 'left',
          label: '',
          field: 'delete',
          type: 'delete',
        },
        {
          name: 'targetPopulation',
          required: true,
          label: 'Target population',
          field: 'targetPopulation',
          align: 'left',
          style: 'width: 350px',
          type: 'editableDropdown',
          isMandatory: true,
          options: this.targetPopulationOptions,
        },
        {
          name: 'standardOfCare',
          align: 'left',
          label:
            'Standard of Care (might be active treatments or supportive measures only)',
          field: 'standardOfCare',
          type: 'editableDropdown',
          isMandatory: true,
          options: this.standardOfCareOptions,
        },
        {
          name: 'mortalityBenefit',
          align: 'left',
          label: 'Do you expect a mortality benefit?',
          field: 'mortalityBenefit',
          type: 'dropdown',
          isMandatory: true,
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'survivalEndpoint',
          align: 'left',
          label:
            'Specify surivival endpoint (e.g. mean/median survival, % of patients surviving xx months/years)?',
          field: 'survivalEndpoint',
          type: 'editable',
        },
        {
          name: 'survivalBenefit',
          align: 'left',
          label: 'Expected survival for intervention?',
          field: 'survivalBenefit',
          type: 'editable',
        },
        {
          name: 'survivalBenefitSoC',
          align: 'left',
          label: 'Expected survival for SoC',
          field: 'survivalBenefitSoC',
          type: 'editable',
        },
      ],
    }
  },
  computed: {
    dynamicCells() {
      const updatedColumns = [...this.columns]

      return updatedColumns
    },
    rowTypes() {
      // Create an empty object to store the rowTypes
      const rowTypesObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        rowTypesObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          switch (column.type) {
            case 'editable':
              rowTypesObj[rowIndex][column.field] = 'editable'
              break
            case 'editableDropdown':
              rowTypesObj[rowIndex][column.field] = 'editableDropdown'
              break
            case 'monthYear':
              rowTypesObj[rowIndex][column.field] = 'monthYear'
              break
            case 'delete':
              rowTypesObj[rowIndex][column.field] = 'delete'
              break
            case 'dropdown':
              rowTypesObj[rowIndex][column.field] = 'dropdown'
              break
            case 'static':
              rowTypesObj[rowIndex][column.field] = 'static'
              break
            // Add more cases if needed
            default:
              rowTypesObj[rowIndex][column.field] = 'static'
          }
        })
      })
      return rowTypesObj
    },
    isMandatory() {
      // Create an empty object to store the rowTypes
      const isMandatoryObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        isMandatoryObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          if (column.isMandatory) {
            isMandatoryObj[rowIndex][column.field] = true
          } else {
            isMandatoryObj[rowIndex][column.field] = false
          }
        })
      })
      return isMandatoryObj
    },
  },
  methods: {
    async newLine() {
      const newRow = {}
      this.columns.forEach((column) => {
        if (column.name !== 'delete') {
          newRow[column.name] = null
        }
      })
      this.rows.push(newRow)
      this.nextStudyId++
    },
    async deleteRow(rowIndex) {
      if (rowIndex !== -1) {
        this.rows.splice(rowIndex, 1)
      }
    },
    updateValue(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionSelected(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    save() {
      const rowsWithEmptyFields = this.rows.filter((row) => {
        return (
          !row.targetPopulation ||
          !row.standardOfCare ||
          !row.mortalityBenefit ||
          row.mortalityBenefit.length === 0
        )
      })

      if (rowsWithEmptyFields.length > 0) {
        this.errorDialog = true
        return
      }

      let data = []
      this.rows.forEach((element) => {
        data.push(element)
      })
      console.log('data', data)
      this.$emit('save-event', data)
    },
    cancel() {
      this.$emit('cancel-event')
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
  },
  async mounted() {
    let templateId = store.getters.getCurrentTemplateId
    let egpId = store.getters.getCurrentEgpId
    this.nextStudyId = await getNextStudyId(egpId)
    const targetPopulationResult = await axios.get(
      '/template/getTargetPopulation/' + egpId,
    )
    const standardOfCareResult = await axios.get(
      '/template/getStandardOfCare/' + egpId,
    )
    // Create a new columns array by deep cloning the existing one
    const newColumns = cloneDeep(this.columns)
    // Find the targetPopulation column and update its options
    const targetPopulationColumn = newColumns.find(
      (col) => col.name === 'targetPopulation',
    )
    const standardOfCareColumn = newColumns.find(
      (col) => col.name === 'standardOfCare',
    )
    // Handle empty or invalid responses
    if (
      !Array.isArray(standardOfCareResult.data) ||
      standardOfCareResult.data.length === 0
    ) {
      standardOfCareColumn.options = []
    } else {
      // Map each entry in the server response to an option object
      standardOfCareColumn.options = standardOfCareResult.data.map((entry) => ({
        label: entry,
        value: entry,
      }))
      standardOfCareColumn.options.push({ label: 'Other: ', value: 'Other:' })
    }
    // Handle empty or invalid responses
    if (
      !Array.isArray(targetPopulationResult.data) ||
      targetPopulationResult.data.length === 0
    ) {
      targetPopulationColumn.options = []
    } else {
      // Map each entry in the server response to an option object
      targetPopulationColumn.options = targetPopulationResult.data.map(
        (entry) => ({
          label: entry,
          value: entry,
        }),
      )
      targetPopulationColumn.options.push({ label: 'Other: ', value: 'Other:' })
    }

    // Assign the new columns array to this.columns
    this.columns = newColumns

    if (templateId && egpId) {
      let responseTemplate = await axios.get(
        '/template/getTemplateAntworten/' + egpId + '/' + templateId,
      )
      console.log('responseTemplate', responseTemplate)
      if (responseTemplate.data && typeof responseTemplate.data === 'object') {
        this.rows = Object.values(responseTemplate.data).map((row) => {
          const newRow = {}
          this.columns.forEach((column) => {
            if (column.name !== 'delete') {
              newRow[column.name] = row[column.name] || null
            }
          })
          return newRow
        })
      } else {
        this.rows = []
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
