<template>
  <q-card-section>
    <div class="q-pa-sm">
      <div class="q-gutter-sm text-primary">
        <div v-for="option in answerOptions" :key="option.value">
          <div class="row items-center q-gutter-md">
            <q-radio
              v-model="selectedAnswer"
              :val="option.value"
              @update:model-value="handleAnswerChange"
            >
              {{ option.label }}
            </q-radio>
            <q-btn
              v-if="
                (option.label.trim().toLowerCase() === 'yes' &&
                  selectedAnswer != 8 &&
                  selectedAnswer != 11 &&
                  componentName) ||
                (option.label.trim() === 'Correlation analyses' &&
                  selectedAnswer === 53) ||
                (option.label.trim() === 'Full Validation Study' &&
                  selectedAnswer === 54) ||
                (option.label.trim() === 'Partly' && selectedAnswer === 8) ||
                (option.label.trim() === 'Partly' && selectedAnswer === 11)
              "
              color="primary"
              label="Edit Template"
              @click="showTemplateDialog(option.value)"
              class="q-ml-md"
            />
          </div>
        </div>
      </div>
    </div>
  </q-card-section>
</template>

<script>
export default {
  name: 'RadioQuestionCard',
  props: {
    question: {
      type: Object,
      required: true,
    },
    componentName: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedAnswer: null,
    }
  },
  computed: {
    answerOptions() {
      return this.question.answers
        ? this.question.answers.map((answer) => ({
            label: answer.text,
            value: answer.id,
          }))
        : []
    },
  },
  methods: {
    handleAnswerChange(answerId) {
      this.selectedAnswer = answerId
      this.$emit('answer-change', [answerId])
    },
    getSelectedAnswerId() {
      const selectedOption = this.question.answers?.find(
        (answer) => answer.isSelected,
      )
      return selectedOption ? selectedOption.id : null
    },
    showTemplateDialog(answerId) {
      this.$emit('show-template-dialog', answerId)
    },
  },
  created() {
    this.selectedAnswer = this.getSelectedAnswerId()
  },
}
</script>
