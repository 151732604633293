<template>
  <div>
    <Questionnaire
      v-if="questionnaireQuestionIds && questionnaireQuestionIds.length"
      :key="questionnaireQuestionId"
      :questionnaireQuestionId="Number(questionnaireQuestionId)"
      :egpId="egpId"
      :isLastSelfCheckQuestion="
        currentQuestionIndex === questionnaireQuestionIds.length - 1
      "
      @next="nextQuestion"
    />
  </div>
</template>

<script>
import store from '@/store.js'
import Questionnaire from './Questionnaire/QuestionnaireView.vue'
export default {
  name: 'selfCheckQuestionnaire',
  components: {
    Questionnaire,
  },

  data() {
    return {
      currentQuestionIndex: 0,
      questionnaireQuestionIds: [],
      egpId: null,
      questionnaireQuestionId: null,
    }
  },
  created() {
    this.questionnaireQuestionIds = this.$route.params.questionnaireQuestionIds
    this.questionnaireQuestionId = Number(
      this.questionnaireQuestionIds[this.currentQuestionIndex],
    )
    store.commit('changeFrageId', this.questionnaireQuestionId)
    this.egpId = this.$route.params.egpId
    this.currentQuestionIndex = 0
  },
  methods: {
    async nextQuestion() {
      if (
        !this.questionnaireQuestionIds ||
        !this.questionnaireQuestionIds.length
      ) {
        console.error(
          'questionnaireQuestionIds is not defined or is an empty array',
        )
        return
      }
      if (
        this.currentQuestionIndex <
        this.questionnaireQuestionIds.length - 1
      ) {
        this.currentQuestionIndex++
        this.questionnaireQuestionId = Number(
          this.questionnaireQuestionIds[this.currentQuestionIndex],
        )
        store.commit('changeFrageId', this.questionnaireQuestionId)
      } else {
        console.log('nextQuestion: no more questions')
        try {
          this.$router.push({ name: 'selfCheck', query: { egpId: this.egpId } })
        } catch (error) {
          console.error('Error while redirecting to SelfCheck: ', error)
        }
      }
    },
  },
}
</script>
