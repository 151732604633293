<template>
  <GipamTable
    :tableTitle="'Planning of Evidence Synthesis Projects'"
    :columns="dynamicCells"
    :rowTypes="rowTypes"
    :rows="rows"
    :rowsPerPage="5"
    :dynamicOptions="dynamicOptions"
    :isMandatory="isMandatory"
    @update-value="updateValue"
    @option-selected="handleOptionSelected"
    @update-options="handleOptionsUpdate"
    @delete-clicked="deleteRow"
  >
    <template v-slot:title-action>
      <q-btn color="primary" label="Add Row" @click="newLine" class="q-ml-md" />
    </template>
  </GipamTable>
  <ErrorDialog
    v-model="errorDialog"
    :message="errorMessage"
    @close-dialog="closeErrorDialog"
  />
  <div class="column">
    <div class="col"></div>
    <div class="col self-end">
      <q-btn color="primary" class="q-mt-md q-mr-sm" @click="this.save"
        >Save</q-btn
      >
      <q-btn color="primary" class="q-mt-md" @click="this.cancel">Cancel</q-btn>
    </div>
  </div>
</template>
<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamMandatoryErrorDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { getNextStudyId } from '@/service/Api.ts'
import store from '@/store.js'
import axios from 'axios'
import { cloneDeep } from 'lodash'

export default {
  components: {
    GipamTable,
    ErrorDialog,
  },
  name: 'Template_10',
  emits: ['save-event', 'cancelEvent'],
  props: {
    msg: String,
  },

  computed: {
    dynamicCells() {
      const updatedColumns = [...this.columns]

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        const finalizationStudyNumberColumn = updatedColumns.find(
          (col) => col.name === 'finalizationStudyNumber',
        )

        const targetPopulationColumn = updatedColumns.find(
          (col) => col.name === 'targetPopulation',
        )

        const targetTreatmentColumn = updatedColumns.find(
          (col) => col.name === 'targetTreatment',
        )

        if (finalizationStudyNumberColumn) {
          const hasFinalizationYes = row.finalization === 'Yes'
          if (hasFinalizationYes) {
            this.rowTypes[rowIndex]['finalizationStudyNumber'] =
              'editableMultipleSelectionDropdown'
            if (this.rows[rowIndex]['finalizationStudyNumber'] === 'N/A') {
              this.rows[rowIndex]['finalizationStudyNumber'] = null
            }
          } else {
            this.rows[rowIndex]['finalizationStudyNumber'] = 'N/A'
            this.rowTypes[rowIndex]['finalizationStudyNumber'] = 'static'
          }
        }

        if (
          targetPopulationColumn &&
          row.targetPopulation &&
          row.targetPopulation.length === 1
        ) {
          const targetPopulationValue = row.targetPopulation[0] // Get the first (and only) element of the array
          const targetPopulationParts = targetPopulationValue.split('*')
          const updatedTargetPopulationParts = targetPopulationParts.map(
            (part) => {
              return part.replace(/###COMMA###/g, ',')
            },
          )

          this.rows[rowIndex]['targetPopulation'] = updatedTargetPopulationParts // Assign targetPopulationParts to the row
        }

        if (
          targetTreatmentColumn &&
          row.targetTreatment &&
          row.targetTreatment.length === 1
        ) {
          const targetTreatmentValue = row.targetTreatment[0] // Get the first (and only) element of the array
          const targetPopulationParts = targetTreatmentValue.split('*')
          const updatedTargetPopulationParts = targetPopulationParts.map(
            (part) => {
              return part.replace(/###COMMA###/g, ',')
            },
          )

          this.rows[rowIndex]['targetTreatment'] = updatedTargetPopulationParts // Assign targetPopulationParts to the row
        }
      })

      return updatedColumns
    },
    rowTypes() {
      // Create an empty object to store the rowTypes
      const rowTypesObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        rowTypesObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          switch (column.type) {
            case 'editable':
              rowTypesObj[rowIndex][column.field] = 'editable'
              break
            case 'editableDropdown':
              rowTypesObj[rowIndex][column.field] = 'editableDropdown'
              break
            case 'editableMultipleSelectionDropdown':
              rowTypesObj[rowIndex][column.field] =
                'editableMultipleSelectionDropdown'
              break
            case 'monthYear':
              rowTypesObj[rowIndex][column.field] = 'monthYear'
              break
            case 'delete':
              rowTypesObj[rowIndex][column.field] = 'delete'
              break
            case 'dropdown':
              rowTypesObj[rowIndex][column.field] = 'dropdown'
              break
            case 'multipleSelectionDropdown':
              rowTypesObj[rowIndex][column.field] = 'multipleSelectionDropdown'
              break
            case 'static':
              rowTypesObj[rowIndex][column.field] = 'static'
              break
            // Add more cases if needed
            default:
              rowTypesObj[rowIndex][column.field] = 'static'
          }
        })
      })
      return rowTypesObj
    },
    isMandatory() {
      // Create an empty object to store the rowTypes
      const isMandatoryObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        isMandatoryObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          if (column.isMandatory) {
            isMandatoryObj[rowIndex][column.field] = true
          } else {
            isMandatoryObj[rowIndex][column.field] = false
          }
        })
      })
      return isMandatoryObj
    },
  },
  data() {
    return {
      errorDialog: false,
      errorMessage:
        'Study ID, Project name / Internal ID, Main study objectives, Study population(s); incl. sub-populations, Study Treatment, Type of Literature Review, Has this study already been started',
      targetPopulationOptions: [],
      dynamicOptions: [],
      nextStudyId: '1',
      columns: [
        {
          name: 'delete',
          align: 'left',
          label: '',
          field: 'delete',
          type: 'delete',
        },
        {
          name: 'studyId',
          required: true,
          label: 'Study Id',
          align: 'left',
          style: 'width: 350px',
          field: 'studyId',
          type: 'static',
          isMandatory: true,
        },
        {
          name: 'trialName',
          align: 'left',
          label: 'Project name/Internal ID',
          field: 'trialName',
          type: 'editable',
          isMandatory: true,
        },
        {
          name: 'studyObjectives',
          align: 'left',
          label: 'Main study objectives',
          field: 'studyObjectives',
          type: 'editable',
          isMandatory: true,
        },
        {
          name: 'targetPopulation',
          align: 'left',
          label: 'Study population(s); incl. sub-populations',
          field: 'targetPopulation',
          type: 'editableMultipleSelectionDropdown',
          isMandatory: true,
          options: this.targetPopulationOptions,
        },
        {
          name: 'targetTreatment',
          align: 'left',
          label: 'Study treatments',
          field: 'targetTreatment',
          type: 'editableMultipleSelectionDropdown',
          isMandatory: true,
          options: this.standardOfCareOptions,
        },
        {
          name: 'targetStudies',
          align: 'left',
          label: 'Type of included studies',
          field: 'targetStudies',
          type: 'editableMultipleSelectionDropdown',

          options: [
            {
              label: 'RCTs',
              value: 'RCTs',
            },
            {
              label: 'Clinical trials',
              value: 'Clinical trials',
            },
            {
              label: 'Observational studies',
              value: 'Observational studies',
            },
            {
              label: 'Reviews',
              value: 'Reviews',
            },
            { label: 'Other: ', value: 'Other:' },
          ],
        },
        {
          name: 'typeLiteratureReview',
          align: 'left',
          label: 'Type of literature review',
          field: 'typeLiteratureReview',
          type: 'dropdown',
          isMandatory: true,
          options: [
            { label: 'TLR', value: 'TLR' },
            { label: 'SLR', value: 'SLR' },
          ],
        },
        {
          name: 'targetCountries',
          align: 'left',
          label: 'Country/Countries',
          field: 'targetCountries',
          type: 'multipleSelectionDropdown',
          options: this.countryOptions,
        },
        {
          name: 'startDate',
          align: 'left',
          label: 'Intended start date',
          field: 'startDate',
          type: 'monthYear',
        },
        {
          name: 'finalizationDate',
          align: 'left',
          label: 'Intended finalization date',
          field: 'finalizationDate',
          type: 'monthYear',
        },
        {
          name: 'studyStarted',
          align: 'left',
          label: 'Has this study already been started?',
          field: 'studyStarted',
          type: 'dropdown',
          isMandatory: true,
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'finalization',
          align: 'left',
          label:
            'Do other study(ies) have to be completed before this study can begin?',
          field: 'finalization',
          type: 'dropdown',
          options: [
            { label: 'Yes', value: 'Yes' },
            { label: 'No', value: 'No' },
          ],
        },
        {
          name: 'finalizationStudyNumber',
          align: 'left',
          label: 'Which study must be completed first?',
          field: 'finalizationStudyNumber',
          type: 'editableMultipleSelectionDropdown',
        },
        {
          name: 'comments',
          align: 'left',
          label: 'Comments/other specifics',
          field: 'comments',
          type: 'editable',
        },
      ],
      rows: [],
    }
  },
  methods: {
    async newLine() {
      const newRow = {}
      this.columns.forEach((column) => {
        if (column.name !== 'delete') {
          newRow[column.name] = null // Use column.name directly as the key
        }
      })
      newRow['studyId'] = this.nextStudyId // Add studyId to the new row
      this.rows.push(newRow) // Push the new row to `this.rows` array
      this.nextStudyId++
    },
    async deleteRow(rowIndex) {
      if (rowIndex !== -1) {
        this.rows.splice(rowIndex, 1)
      }
    },
    updateValue(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionSelected(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionsUpdate(updatedOptions, rowIndex, columnIndex) {
      const columnName = this.columns[columnIndex].name
      const updatedOptionsForColumn = {
        [columnName]: updatedOptions,
      }

      // Update the dynamicOptions array with the updated options
      this.dynamicOptions.splice(rowIndex, 1, updatedOptionsForColumn)

      // You might also want to update the options in the corresponding column
      const correspondingColumn = this.columns.find(
        (col) => col.name === columnName,
      )
      if (correspondingColumn) {
        correspondingColumn.options = updatedOptions
      }
    },
    save() {
      const rowsWithEmptyFields = this.rows.filter((row) => {
        return (
          !row.studyId ||
          !row.trialName ||
          !row.studyObjectives ||
          !row.targetPopulation ||
          !row.targetTreatment ||
          !row.typeLiteratureReview ||
          !row.studyStarted ||
          row.studyStarted.length === 0
        )
      })

      if (rowsWithEmptyFields.length > 0) {
        this.errorDialog = true
        return
      }

      let data = []
      this.rows.forEach((element) => {
        data.push(element)
      })

      this.$emit('save-event', data)
    },
    cancel() {
      this.$emit('cancel-event')
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
  },
  async mounted() {
    let templateId = store.getters.getCurrentTemplateId
    let egpId = store.getters.getCurrentEgpId
    this.nextStudyId = await getNextStudyId(egpId)
    // Initialize dynamicOptions with the same options as columns
    this.dynamicOptions = this.columns.map((col) => ({
      [col.name]: col.options || [],
    }))

    const targetPopulationResult = await axios.get(
      '/template/getTargetPopulation/' + egpId,
    )
    const standardOfCareResult = await axios.get(
      '/template/getStandardOfCare/' + egpId,
    )
    const finalizationStudyNumberResult = await axios.get(
      '/template/finalizationStudyNumber/' + egpId,
    )
    // Create a new columns array by deep cloning the existing one
    const newColumns = cloneDeep(this.columns)
    // Find the targetPopulation column and update its options
    const targetPopulationColumn = newColumns.find(
      (col) => col.name === 'targetPopulation',
    )
    const standardOfCareColumn = newColumns.find(
      (col) => col.name === 'targetTreatment',
    )
    const finalizationStudyNumberColumn = newColumns.find(
      (col) => col.name === 'finalizationStudyNumber',
    )
    const targetCountriesColumn = newColumns.find(
      (col) => col.name === 'targetCountries',
    )
    const loadCountryOptionsResult = await axios.get('/egp/loadCountryOptions/')
    // Handle empty or invalid responses
    if (
      !Array.isArray(loadCountryOptionsResult.data) ||
      loadCountryOptionsResult.data.length === 0
    ) {
      targetCountriesColumn.options = []
    } else {
      // Map each entry in the server response to an option object
      targetCountriesColumn.options = loadCountryOptionsResult.data.map(
        (entry) => ({
          label: entry.en,
          value: entry.en,
        }),
      )
      targetCountriesColumn.options.unshift({
        label: 'Global',
        value: 'Global',
      })
    }

    if (
      !Array.isArray(finalizationStudyNumberResult.data) ||
      finalizationStudyNumberResult.data.length === 0
    ) {
      finalizationStudyNumberColumn.options = []
      finalizationStudyNumberColumn.options.push({
        label: 'Other: ',
        value: 'Other:',
      })
    } else {
      // Map each entry in the server response to an option object
      finalizationStudyNumberColumn.options =
        finalizationStudyNumberResult.data.map((entry) => ({
          label: entry,
          value: entry,
        }))
      finalizationStudyNumberColumn.options.push({
        label: 'Other: ',
        value: 'Other:',
      })
    }

    // Handle empty or invalid responses
    if (
      !Array.isArray(standardOfCareResult.data) ||
      standardOfCareResult.data.length === 0
    ) {
      standardOfCareColumn.options = []
    } else {
      // Map each entry in the server response to an option object
      standardOfCareColumn.options = standardOfCareResult.data.map((entry) => ({
        label: entry,
        value: entry,
      }))
      standardOfCareColumn.options.push({ label: 'Other:', value: 'Other:' })
    }
    // Handle empty or invalid responses
    if (
      !Array.isArray(targetPopulationResult.data) ||
      targetPopulationResult.data.length === 0
    ) {
      targetPopulationColumn.options = []
    } else {
      // Map each entry in the server response to an option object
      targetPopulationColumn.options = targetPopulationResult.data.map(
        (entry) => ({
          label: entry,
          value: entry,
        }),
      )
      targetPopulationColumn.options.push({ label: 'Other:', value: 'Other:' })
    }
    // Assign the new columns array to this.columns
    this.columns = newColumns

    if (templateId && egpId) {
      let responseTemplate = await axios.get(
        '/template/getTemplateAntworten/' + egpId + '/' + templateId,
      )
      if (responseTemplate.data && typeof responseTemplate.data === 'object') {
        this.rows = Object.values(responseTemplate.data).map((row) => {
          // Create a new object with the same keys as the columns
          const newRow = {}
          this.columns.forEach((column) => {
            if (column.name !== 'delete') {
              newRow[column.name] = row[column.name] || null
            }
          })
          return newRow
        })
      } else {
        this.rows = []
      }
    }
  },
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
