// AnswerService.js
import store from '@/store.js'
import QuestionService from './QuestionService.ts'
import TemplateService from './TemplateService.ts'

export default {
  async processAnswerIds(answerIds) {
    const template = await TemplateService.getTemplateByAnswerId(
      answerIds[answerIds.length - 1],
    )
    const responseTemplate = await TemplateService.handleTemplateResponse(
      template,
      store.getters.getCurrentEgpId,
    )
    if (responseTemplate) {
      return responseTemplate
    }
    return null
  },

  async saveAnswer(givenAnswerIds, currentQuestion) {
    if (givenAnswerIds.length === 0) {
      return
    }
    const givenAnswer = {
      answerIds: givenAnswerIds,
      egpId: store.getters.getCurrentEgpId,
      questionId: currentQuestion.id,
    }
    await QuestionService.saveAnswer(givenAnswer)
  },
}
