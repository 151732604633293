<template>
  <q-td style="width: max-content; max-width: 90vw; min-width: 200px">
    <DropdownCellView
      v-if="type === 'dropdown'"
      :modelValue="content"
      :options="options"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
    />
    <NumericalEditableCellView
      v-if="type === 'numericalDropdown'"
      :modelValue="content"
      :columnTitle="columnTitle"
      :isMandatory="isMandatory"
      @update:modelValue="handleUpdateValue"
    />
    <MultipleSelectionDropdownCellView
      v-if="type === 'multipleSelectionDropdown'"
      :modelValue="content"
      :options="options"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
    />
    <EditableMultipleSelectionDropdownCellView
      v-if="type === 'editableMultipleSelectionDropdown'"
      :modelValue="content"
      :options="options"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
      @update:options="handleOptionsUpdate"
    />
    <LinkCellView
      v-else-if="type === 'link'"
      :content="content"
      :isMandatory="isMandatory"
      @link-clicked="handleLinkClicked"
    />
    <EditableCellView
      v-else-if="type === 'editable'"
      :modelValue="content"
      :columnTitle="columnTitle"
      :isMandatory="isMandatory"
      @update:modelValue="handleUpdateValue"
    />
    <EditableDropdownCellView
      v-if="type === 'editableDropdown'"
      :modelValue="content"
      :options="options"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
      @update:options="handleOptionsUpdate"
    />
    <CountableCellView
      v-if="type === 'countable'"
      :modelValue="countableValue"
      :stepSize="1"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
    />
    <MonthYearCellView
      v-if="type === 'monthYear'"
      :content="{ value: content }"
      :isMandatory="isMandatory"
      @update:modelValue="handleModelValueUpdate"
    />
    <DeleteCellView
      v-if="type === 'delete'"
      @delete-clicked="handleDeleteClicked"
    />
    <StaticCellView v-if="type === 'static'" :content="content" />
  </q-td>
</template>

<script>
import CountableCellView from './GipamCountableCellView.vue'
import DeleteCellView from './GipamDeleteCellView.vue'
import DropdownCellView from './GipamDropdownCellView.vue'
import EditableCellView from './GipamEditableCellView.vue'
import EditableDropdownCellView from './GipamEditableDropdownCellView.vue'
import EditableMultipleSelectionDropdownCellView from './GipamEditableMultipleSelectionDropdownCellView.vue'
import LinkCellView from './GipamLinkCellView.vue'
import MonthYearCellView from './GipamMonthYearCellView.vue'
import MultipleSelectionDropdownCellView from './GipamMultipleSelectionDropdownCellView.vue'
import NumericalEditableCellView from './GipamNumericalEditableCellView.vue'
import StaticCellView from './GipamStaticCellView.vue'

export default {
  components: {
    DeleteCellView,
    EditableDropdownCellView,
    DropdownCellView,
    LinkCellView,
    EditableCellView,
    StaticCellView,
    CountableCellView,
    MonthYearCellView,
    NumericalEditableCellView,
    MultipleSelectionDropdownCellView,
    EditableMultipleSelectionDropdownCellView,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    isMandatory: {
      type: Boolean,
      default: false,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    columnIndex: {
      type: Number,
      required: true,
    },
    columnTitle: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    content: {
      type: [Array, String, Object],
      default: '',
    },
    headerOptions: {
      type: Object,
    },
  },
  data() {
    return {
      localContent: this.getInitialLocalContent(this.content),
      countableValue: this.getInitialCountableValue(this.content),
    }
  },
  methods: {
    getInitialLocalContent(content) {
      return typeof content === 'object' && content !== null
        ? content.label || ''
        : content
    },
    getInitialCountableValue(content) {
      if (typeof content === 'string' && content.includes(' weeks')) {
        return content
      }
      return '1 weeks'
    },
    handleModelValueUpdate(value) {
      this.$emit('option-selected', value, this.rowIndex, this.columnIndex)
    },
    handleOptionsUpdate(value) {
      this.$emit('update-options', value, this.rowIndex, this.columnIndex)
    },
    handleLinkClicked() {
      this.$emit('link-clicked', this.rowIndex, this.columnIndex)
    },
    handleDeleteClicked() {
      this.$emit('delete-clicked', this.rowIndex)
    },
    handleUpdateValue(newVal) {
      this.$emit('update-value', newVal, this.rowIndex, this.columnIndex)
    },
  },
}
</script>
<style>
.grey-row:hover {
  background-color: grey;
}

.blue-row:hover {
  background-color: blue;
}
.header-cell {
  position: relative;
}

.header-label {
  display: inline-block;
}
</style>
