<template>
  <div class="editable-cell-container">
    <q-select
      v-model="selectedOption"
      :options="options"
      :modelValue="modelValue"
      :isMandatory="isMandatory"
      @update:model-value="handleModelValueUpdate"
      emit-value
      map-options
      new-value-mode="add-unique"
      :disable="showEditModal"
      multiple
      style="width: max-content; max-width: 90vw; min-width: 200px"
    >
      <template v-slot:selected>
        <div class="row items-center">
          <span v-if="!(selectedOption && selectedOption.length > 0)">
            Select an option
          </span>
          <div
            v-else
            class="col"
            style="display: flex; flex-direction: column; margin-left: 0.5rem"
          >
            <div v-for="(option, index) in selectedOption" :key="index">
              <div class="row items-center">
                {{ option.label }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMandatory: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedOption: this.modelValue,
      showEditModal: false,
      editedLabel: '',
      editedOption: null,
    }
  },
  watch: {
    modelValue(newValue) {
      if (Array.isArray(newValue)) {
        this.selectedOption = newValue.map((value) => {
          const option = this.options.find((opt) => opt.label === value)
          return option || { label: value, value }
        })
      } else {
        this.selectedOption = newValue
      }
    },
    options: {
      handler(newOptions) {
        if (newOptions.length > 0 && this.modelValue.length === 0) {
          const lastOption = newOptions[newOptions.length - 1]
          this.$emit('update:modelValue', [lastOption.value])
        }
      },
      deep: true,
    },
  },
  computed: {
    isOtherOptionSelected() {
      if (!Array.isArray(this.options) || this.options.length === 0) {
        return false
      }
      const otherOption = this.options.find((option) => option.value === null)
      return otherOption && this.selectedOption === otherOption.value
    },
  },
  mounted() {
    // Check if this.modelValue is not null or undefined
    if (
      this.modelValue !== null &&
      this.modelValue !== undefined &&
      this.modelValue.length > 0
    ) {
      // Split the value by '*' to get the parts
      const targetPopulationParts = this.modelValue.split('*')
      // Map over the parts and replace '###COMMA###' with ','
      const updatedTargetPopulationParts = targetPopulationParts.map((part) =>
        part.replace(/###COMMA###/g, ','),
      )
      const modelValueArray = updatedTargetPopulationParts

      // Split each item in the array by comma
      const separatedModelValues = modelValueArray.flatMap((value) =>
        value.split(',').map((item) => item.trim()),
      )
      console.log('separatedModelValues:', separatedModelValues)

      this.selectedOption = separatedModelValues
      this.$emit('update:modelValue', separatedModelValues)
    } else {
      console.warn('this.modelValue is null or undefined')
      // Handle the case when this.modelValue is null or undefined
      // For example, you could emit an empty array
      this.$emit('update:modelValue', [])
      this.selectedOption = []
    }
  },
  methods: {
    handleModelValueUpdate(value) {
      let selectedValues = value.map((option) => {
        if (typeof option === 'object' && option !== null) {
          // Handle proxy object
          return option.value
        } else {
          // Handle string value (new option)
          return option
        }
      })

      // Check if 'Global' is present in selectedValues
      const hasGlobal = selectedValues.includes('Global')
      const hasAnyTreamtent = selectedValues.includes('Any Treatment')

      if (hasGlobal) {
        // If 'Global' is present, reset selectedValues to contain only 'Global'
        selectedValues = ['Global']
      } else if (hasAnyTreamtent) {
        selectedValues = ['Any Treatment']
      }

      this.$emit('update:modelValue', selectedValues)
    },
  },
}
</script>
<style scoped>
.editable-cell-container {
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
