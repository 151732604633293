// SelfCheckService.ts
import DataService from '@/service/DataService.ts'
import axios from 'axios'

export default {
  async saveSelfCheck(egpId, selfCheckData) {
    try {
      let dataToSave = []
      for (let i = 0; i < selfCheckData.rows.length; i++) {
        let row = selfCheckData.rows[i]

        if (typeof row.answer === 'string') {
          let dto = {
            egpId: egpId,
            QuestionId: row.id,
            AnswerId: row.answer,
          }
          dataToSave.push(dto)
        }
      }

      if (dataToSave.length > 0) {
        await DataService.saveSelfCheck(dataToSave)
      } else {
        console.warn('Es gibt keine Daten zum Speichern.')
      }
    } catch (error) {
      console.error('Fehler beim Speichern der Daten: ', error)
    }
  },
  async getQuestionnaireQuestionIdBySelfCheckQuestionId(selfCheckQuestionId) {
    let questionIds = []
    try {
      const response = await axios.get(
        `/questionnaire/getQuestionnaireQuestionIdBySelfCheckQuestionId/${selfCheckQuestionId}`,
      )
      response.data.forEach((questionId) => {
        questionIds.push(questionId)
      })
      return questionIds
    } catch (error) {
      console.error('Fehler beim Laden der Daten: ', error)
    }
  },

  async showTemplateLink(selfCheckQuestionId, egpId, router) {
    const questionnaireQuestionIds =
      await this.fetchQuestionnaireQuestionIds(selfCheckQuestionId)
    this.navigateToSelfCheckQuestionnaire(
      questionnaireQuestionIds,
      egpId,
      router,
    )
  },

  navigateToSelfCheckQuestionnaire(questionnaireQuestionIds, egpId, router) {
    console.log('questionnaireQuestionIds to route: ', questionnaireQuestionIds)
    router.push({
      name: 'selfCheckQuestionnaire',
      params: {
        questionnaireQuestionIds: questionnaireQuestionIds,
        egpId: egpId,
      },
    })
  },

  async fetchQuestionnaireQuestionIds(selfCheckQuestionId) {
    return await this.getQuestionnaireQuestionIdBySelfCheckQuestionId(
      selfCheckQuestionId,
    )
  },
}
