<template>
  <q-btn
    round
    color="primary"
    size="sm"
    icon="delete_forever"
    @click="handleClick"
  />
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit('delete-clicked')
    },
  },
}
</script>
