<template>
  <q-dialog v-model="templateDialog" full-width>
    <q-card>
      <q-card-section class="q-pt-md">
        <div class="text-h6">Thank you for answering all these questions.</div>
        <div class="q-my-md">Please choose what you would like to do next:</div>
        <div class="q-gutter-md">
          <q-btn
            color="primary"
            label="Monitoring of the Evidence Generation Plan"
            @click="goToMonitoring"
          />
          <q-btn
            color="primary"
            label="Self-check of the Evidence Generation Plan"
            @click="selfCheckTemplate"
          />
          <q-btn color="grey" label="Print the Evidence Generation Plan" />
          <q-btn color="primary" class="q-mt-md" @click="cancelTemplate"
            >Cancel</q-btn
          >
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      templateDialog: false, // define errorDialog here
    }
  },
  methods: {
    goToMonitoring() {
      this.$emit('go-to-monitoring')
    },
    cancelTemplate() {
      this.$emit('cancel-template')
    },
    selfCheckTemplate() {
      this.$emit('go-to-selfCheck')
    },
    async printPDFTemplate() {
      this.$emit('print-PDF')
    },
  },
}
</script>
