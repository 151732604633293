// DataService.ts
import axios from 'axios'

export default {
  async getSelfCheck(egpId) {
    return await axios.get('/questionnaire/getSelfCheck/' + egpId)
  },

  async saveSelfCheck(dataToSave) {
    return await axios.post('/questionnaire/saveSelfCheck', dataToSave)
  },

  async loadSavedAnswers(egpId) {
    return await axios.get(`/questionnaire/loadSelfCheckAnswers/${egpId}`)
  },
}
