import axios from 'axios'

export async function fetchData(egpId) {
  try {
    const response = await axios.get(`template/getMonitoringTemplates/${egpId}`)
    const categorizedData = Array.isArray(response.data)
      ? response.data
      : Object.entries(response.data).flatMap(([templateId, data]) => {
          if (typeof data === 'object' && data !== null) {
            return {
              ...data,
            }
          } else {
            console.warn(`Unexpected data format for templateId ${templateId}`)
            return {}
          }
        })
    return categorizedData
  } catch (error) {
    console.error('Error fetching data from backend', error)
    return []
  }
}

export async function saveData(egpId, data) {
  try {
    const processedData = data.map(
      ({
        studyId,
        templateId,
        category,
        studyObjectives,
        countries,
        startDate,
        studyStarted,
        currentStatus,
        comments,
        linkTemplate,
        finalizationDate,
      }) => ({
        studyId,
        templateId,
        category,
        studyObjectives,
        countries,
        startDate,
        studyStarted,
        finalizationDate,
        CurrentStatus: typeof currentStatus === 'string' ? currentStatus : null,
        comments,
        linkTemplate,
      }),
    )

    await axios.post(`template/saveMonitoringTemplates/${egpId}`, processedData)
  } catch (error) {
    console.error('Error saving data:', error)
  }
}
