import axios from 'axios'

export async function fetchStudyOptions(egpId) {
  try {
    const response = await axios.get(`template/getStudyTopics/${egpId}`)
    return response.data
  } catch (error) {
    console.error('Error fetching data from backend', error)
    return []
  }
}
