<template>
  <GipamTable
    :tableTitle="'Your target population(s)'"
    :columns="dynamicCells"
    :rowTypes="rowTypes"
    :rows="rows"
    :rowsPerPage="5"
    :isMandatory="isMandatory"
    @update-value="updateValue"
    @delete-clicked="deleteRow"
  >
    <template v-slot:title-action>
      <q-btn color="primary" label="Add Row" @click="newLine" class="q-ml-md" />
    </template>
  </GipamTable>
  <ErrorDialog
    v-model="errorDialog"
    :message="errorMessage"
    @close-dialog="closeErrorDialog"
  />
  <div class="column">
    <div class="col"></div>
    <div class="col self-end">
      <q-btn color="primary" class="q-mt-md q-mr-sm" @click="this.save"
        >Save</q-btn
      >
      <q-btn color="primary" class="q-mt-md" @click="this.cancel">Cancel</q-btn>
    </div>
  </div>
</template>
<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamMandatoryErrorDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { getNextStudyId } from '@/service/Api.ts'
import store from '@/store.js'
import axios from 'axios'
import { ref } from 'vue'

const columns = [
  {
    name: 'delete',
    align: 'left',
    label: '',
    field: 'delete',
    type: 'delete',
  },
  {
    name: 'diseaseArea',
    label: 'Disease Area',
    field: 'diseaseArea',
    align: 'left',
    type: 'editable',
    isMandatory: true,
  },
  {
    name: 'diseaseSpecifics',
    label: 'Disease Specifics',
    field: 'diseaseSpecifics',
    align: 'left',
    type: 'editable',
  },
  {
    name: 'previousTreatment',
    label: 'Any previous (failed) treatment(s)',
    field: 'previousTreatment',
    align: 'left',
    type: 'editable',
  },
  {
    name: 'lineOfTreatment',
    label: 'Line of Treatment',
    field: 'lineOfTreatment',
    align: 'left',
    type: 'editable',
  },
  {
    name: 'otherCriteria',
    label: 'Other Criteria',
    field: 'otherCriteria',
    align: 'left',
    type: 'editable',
  },
]

const rows = []

export default {
  components: {
    GipamTable,
    ErrorDialog,
  },
  name: 'Template_1',
  emits: ['save-event', 'cancel-event'],
  props: {
    msg: String,
  },
  setup() {
    return {
      columns,
      rows: ref(rows),
    }
  },
  data() {
    return {
      errorDialog: false,
      errorMessage: 'Disease Area',
      nextStudyId: '1',
    }
  },

  methods: {
    async newLine() {
      const newRow = {}
      this.columns.forEach((column) => {
        if (column.name !== 'delete') {
          newRow[column.name] = null
        }
      })
      this.rows.push(newRow)
      this.nextStudyId++
    },
    async deleteRow(rowIndex) {
      if (rowIndex !== -1) {
        this.rows.splice(rowIndex, 1)
      }
    },
    updateValue(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    save() {
      const rowsWithEmptyDiseaseArea = this.rows.filter(
        (row) => !row.diseaseArea,
      )

      if (rowsWithEmptyDiseaseArea.length > 0) {
        this.errorDialog = true
        return
      }

      let data = []
      this.rows.forEach((element) => {
        data.push(element)
      })

      this.$emit('save-event', data)
    },
    cancel() {
      this.$emit('cancel-event')
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
  },
  computed: {
    dynamicCells() {
      const updatedColumns = [...this.columns]

      return updatedColumns
    },
    rowTypes() {
      // Create an empty object to store the rowTypes
      const rowTypesObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        rowTypesObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          switch (column.type) {
            case 'editable':
              rowTypesObj[rowIndex][column.field] = 'editable'
              break
            case 'editableDropdown':
              rowTypesObj[rowIndex][column.field] = 'editableDropdown'
              break
            case 'monthYear':
              rowTypesObj[rowIndex][column.field] = 'monthYear'
              break
            case 'delete':
              rowTypesObj[rowIndex][column.field] = 'delete'
              break
            case 'dropdown':
              rowTypesObj[rowIndex][column.field] = 'dropdown'
              break
            case 'static':
              rowTypesObj[rowIndex][column.field] = 'static'
              break
            // Add more cases if needed
            default:
              rowTypesObj[rowIndex][column.field] = 'static'
          }
        })
      })
      return rowTypesObj
    },
    isMandatory() {
      // Create an empty object to store the rowTypes
      const isMandatoryObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        isMandatoryObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          if (column.isMandatory) {
            isMandatoryObj[rowIndex][column.field] = true
          } else {
            isMandatoryObj[rowIndex][column.field] = false
          }
        })
      })
      return isMandatoryObj
    },
  },
  async mounted() {
    let templateId = store.getters.getCurrentTemplateId
    let egpId = store.getters.getCurrentEgpId
    this.nextStudyId = await getNextStudyId(egpId)

    if (templateId && egpId) {
      let responseTemplate = await axios.get(
        '/template/getTemplateAntworten/' + egpId + '/' + templateId,
      )

      if (responseTemplate.data && typeof responseTemplate.data === 'object') {
        this.rows = Object.values(responseTemplate.data).map((row) => {
          const newRow = {}
          this.columns.forEach((column) => {
            if (column.name !== 'delete') {
              newRow[column.name] = row[column.name] || null
            }
          })
          return newRow
        })
      } else {
        this.rows = []
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
