<template>
  <q-page>
    <q-card-section class="q-pt-md">
      <component
        :is="component.name"
        @save-event="templateSave"
        @cancel-event="templateCancel"
      />
    </q-card-section>
  </q-page>
</template>

<style></style>

<script>
import router from '@/router'
import store from '@/store.js'
import axios from 'axios'

import template1 from '@/components/templates/template1.vue'
import template10 from '@/components/templates/template10.vue'
import template11 from '@/components/templates/template11.vue'
import template12 from '@/components/templates/template12.vue'
import template13 from '@/components/templates/template13.vue'
import template14 from '@/components/templates/template14.vue'
import template15 from '@/components/templates/template15.vue'
import template16 from '@/components/templates/template16.vue'
import template17 from '@/components/templates/template17.vue'
import template18 from '@/components/templates/template18.vue'
import template19 from '@/components/templates/template19.vue'
import template2 from '@/components/templates/template2.vue'
import template20 from '@/components/templates/template20.vue'
import template21 from '@/components/templates/template21.vue'
import template22 from '@/components/templates/template22.vue'
import template23 from '@/components/templates/template23.vue'
import template24 from '@/components/templates/template24.vue'
import template25 from '@/components/templates/template25.vue'
import template26 from '@/components/templates/template26.vue'
import template3 from '@/components/templates/template3.vue'
import template4 from '@/components/templates/template4.vue'
import template5 from '@/components/templates/template5.vue'
import template6 from '@/components/templates/template6.vue'
import template7 from '@/components/templates/template7.vue'
import template8 from '@/components/templates/template8.vue'
import template9 from '@/components/templates/template9.vue'

export default {
  name: 'EditTemplate',
  components: {
    template1,
    template2,
    template3,
    template4,
    template5,
    template6,
    template7,
    template8,
    template9,
    template10,
    template11,
    template12,
    template13,
    template14,
    template15,
    template16,
    template17,
    template18,
    template19,
    template20,
    template21,
    template22,
    template23,
    template24,
    template25,
    template26,
  },
  data() {
    return {
      egpId: null,
      templateId: null,
      component: {
        id: 0,
        name: '',
      },
    }
  },
  methods: {
    async templateSave(data) {
      //Save template
      let egpId = store.getters.getCurrentEgpId
      let templateId = this.component.id

      // Create the Answers dictionary
      let answers = {}
      for (let questionId in data) {
        let questionAnswers = {}
        for (let answerId in data[questionId]) {
          let answerValue = data[questionId][answerId]
          if (Array.isArray(answerValue)) {
            answerValue = answerValue.join('*') // Change the delimiter to '*'
          } else {
            answerValue = answerValue ? answerValue.toString() : ''
          }
          questionAnswers[answerId] = answerValue
        }
        answers[questionId] = questionAnswers
      }

      let dto = { epgId: egpId, templateId: templateId, answers: answers }

      try {
        let controllerMethod = '/template/save'

        await axios.post(controllerMethod, dto)
      } catch (err) {
        console.log('edit template: ', err.response)
      }

      router.back()
    },
    async templateCancel() {
      router.back()
    },
  },
  async mounted() {
    this.egpId = this.$route.query.egpId
    this.templateId = this.$route.query.templateId
    let responseTemplate = await axios.get(
      '/template/getTemplateById/' + this.templateId,
    )

    if (responseTemplate.data != '') {
      this.component = responseTemplate.data

      store.commit('changeTemplateId', this.templateId)
      store.commit('changeEgpId', this.egpId)
    }
  },
}
</script>
