<template>
  <q-dialog v-model="errorDialog">
    <q-card>
      <q-card-section>
        <div class="text-h6">
          Please fill in the following mandatory fields:
        </div>
        <ul>
          <li v-for="field in mandatoryFields" :key="field">{{ field }}</li>
        </ul>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="closeErrorDialog" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ['message'],
  data() {
    return {
      errorDialog: false,
    }
  },
  computed: {
    mandatoryFields() {
      return this.message.split(', ')
    },
  },
  methods: {
    closeErrorDialog() {
      this.errorDialog = false
      this.$emit('close-dialog')
    },
  },
}
</script>
