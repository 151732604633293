<template>
  <q-dialog v-model="modelValue">
    <q-card>
      <q-card-section>
        <div class="text-h6">Changes saved successfully.</div>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn color="primary" label="OK" @click="closeDialog" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script src="./GipamSaveDialogController.ts"></script>
```
