<template>
  <q-select
    @filter="filterFn"
    :modelValue="modelValue"
    @update:modelValue="handleModelValueUpdate"
    :options="filteredOptions"
    :isMandatory="isMandatory"
    emit-value
    map-options
    style="width: max-content; max-width: 90vw; min-width: 200px"
  >
    <template v-slot:selected>
      <span :class="{ 'text-negative': isMandatory && isPlaceholder }">{{
        displayText
      }}</span>
    </template>
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey">No options available</q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, Boolean, Object, null],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    isMandatory: {
      type: Boolean,
    },
  },
  data() {
    return {
      filteredOptions: this.options,
      stringOptions: [],
    }
  },
  created() {
    this.stringOptions = this.options.map((option) => option.label)
  },
  mounted() {
    const modelValueArray = this.modelValue ? this.modelValue : []
    this.$emit('update:modelValue', modelValueArray)
  },
  computed: {
    displayText() {
      if (this.modelValue === null || this.modelValue.length === 0) {
        return 'Select an option'
      } else {
        const modelValueStr =
          typeof this.modelValue === 'number'
            ? this.modelValue.toString()
            : this.modelValue
        const selectedOptions = this.options.filter((option) =>
          modelValueStr.includes(option.label),
        )
        return selectedOptions.map((option) => option.label).join(', ')
      }
    },
    isPlaceholder() {
      return this.modelValue === null || this.modelValue.length === 0
    },
  },
  methods: {
    handleModelValueUpdate(value) {
      this.$emit('update:modelValue', value)
    },
    filterFn(val, update) {
      if (val === '') {
        update(() => {
          this.filteredOptions = this.stringOptions
        })
        return
      }

      update(() => {
        const needle = val.toLowerCase()
        this.filteredOptions = this.stringOptions.filter((option) =>
          option.toLowerCase().includes(needle),
        )
      })
    },
  },
}
</script>
