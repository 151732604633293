<template>
  <GipamTable
    :tableTitle="'Evidence Gap Assessment'"
    :columns="dynamicCells"
    :rowTypes="rowTypes"
    :rows="rows"
    :rowsPerPage="5"
    @update-value="updateValue"
    @option-selected="handleOptionSelected"
    @delete-clicked="deleteRow"
    ><template v-slot:title-action>
      <q-btn color="primary" label="Add Row" @click="newLine" class="q-ml-md" />
    </template>
  </GipamTable>
  <ErrorDialog
    v-model="errorDialog"
    :message="errorMessage"
    @close-dialog="closeErrorDialog"
  />
  <div class="column">
    <div class="col"></div>
    <div class="col self-end">
      <q-btn color="primary" class="q-mt-md q-mr-sm" @click="this.save"
        >Save</q-btn
      >
      <q-btn color="primary" class="q-mt-md" @click="this.cancel">Cancel</q-btn>
    </div>
  </div>
</template>
<script>
import ErrorDialog from '@/core_controls/GipamErrorDialog/GipamMandatoryErrorDialogView.vue'
import GipamTable from '@/core_controls/GipamTable/GipamTableView.vue'
import { getNextStudyId } from '@/service/Api.ts'
import store from '@/store.js'
import axios from 'axios'
import { ref } from 'vue'
const columns = [
  {
    name: 'delete',
    align: 'left',
    label: '',
    field: 'delete',
    type: 'delete',
  },
  {
    name: 'studyId',
    required: true,
    label: 'Study Id',
    field: 'studyId',
    align: 'left',
    style: 'width: 350px',
    type: 'static',
  },
  {
    name: 'trialName',
    align: 'left',
    label: 'Project name / Internal ID',
    field: 'trialName',
    type: 'editable',
  },
  {
    name: 'study',
    required: true,
    label: 'Study',
    align: 'left',
    style: 'width: 350px',
    type: 'editable',
  },
  {
    name: 'studyCategory',
    align: 'left',
    label: 'Study category',
    field: 'studyCategory',
    type: 'editable',
  },
  {
    name: 'studyObjectives',
    align: 'left',
    label: 'Main study objectives & outcomes',
    field: 'studyObjectives',
    type: 'editable',
  },
  {
    name: 'targetCountries',
    align: 'left',
    label: 'Country/Countries',
    field: 'targetCountries',
    type: 'dropdown',
  },
  {
    name: 'studyStarted',
    align: 'left',
    label: 'Has this study already been started?',
    field: 'studyStarted',
    type: 'editable',
  },
  {
    name: 'comments',
    align: 'left',
    label: 'Comments/other specifics',
    field: 'comments',
    type: 'editable',
  },
]

const rows = []

export default {
  components: {
    GipamTable,
    ErrorDialog,
  },
  name: 'Template_26',
  emits: ['save-event', 'cancelEvent'],
  props: {
    msg: String,
  },
  setup() {
    return {
      columns,
      rows: ref(rows),
    }
  },
  data() {
    return {
      errorDialog: false,
      errorMessage: 'Desease Area',
      nextStudyId: '1',
    }
  },
  computed: {
    dynamicCells() {
      const updatedColumns = [...this.columns]

      return updatedColumns
    },
    rowTypes() {
      // Create an empty object to store the rowTypes
      const rowTypesObj = {}

      // Iterate over each row
      this.rows.forEach((row, rowIndex) => {
        // Create an object for the current row
        rowTypesObj[rowIndex] = {}

        // Iterate over each column
        this.columns.forEach((column) => {
          // Determine the type based on the column.type property
          switch (column.type) {
            case 'editable':
              rowTypesObj[rowIndex][column.field] = 'editable'
              break
            case 'editableDropdown':
              rowTypesObj[rowIndex][column.field] = 'editableDropdown'
              break
            case 'monthYear':
              rowTypesObj[rowIndex][column.field] = 'monthYear'
              break
            case 'delete':
              rowTypesObj[rowIndex][column.field] = 'delete'
              break
            case 'dropdown':
              rowTypesObj[rowIndex][column.field] = 'dropdown'
              break
            case 'multipleSelectionDropdown':
              rowTypesObj[rowIndex][column.field] = 'multipleSelectionDropdown'
              break
            case 'static':
              rowTypesObj[rowIndex][column.field] = 'static'
              break
            // Add more cases if needed
            default:
              rowTypesObj[rowIndex][column.field] = 'static'
          }
        })
      })
      return rowTypesObj
    },
  },
  methods: {
    async newLine() {
      const newRow = {}
      this.columns.forEach((column) => {
        if (column.name !== 'delete') {
          newRow[column.name] = null
        }
      })
      newRow['studyId'] = this.nextStudyId
      this.rows.push(newRow)
      this.nextStudyId++
    },
    async deleteRow(rowIndex) {
      if (rowIndex !== -1) {
        this.rows.splice(rowIndex, 1)
      }
    },
    updateValue(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    handleOptionSelected(value, rowIndex, columnIndex) {
      const fieldName = this.columns[columnIndex].field
      this.rows[rowIndex][fieldName] = value
    },
    save() {
      const rowsWithEmptyFields = this.rows.filter((row) => {
        return (
          !row.studyId ||
          !row.trialName ||
          !row.phase ||
          !row.studyObjectives ||
          !row.targetPopulation ||
          !row.studyDesign ||
          !row.interventionArms ||
          !row.controlArm ||
          !row.studyStarted
        )
      })

      if (rowsWithEmptyFields.length > 0) {
        this.errorDialog = true
        return
      }

      let data = []
      this.rows.forEach((element) => {
        data.push(element)
      })

      this.$emit('save-event', data)
    },
    cancel() {
      this.$emit('cancel-event')
    },
    closeErrorDialog() {
      this.errorDialog = false
    },
  },
  async mounted() {
    let templateId = store.getters.getCurrentTemplateId
    let egpId = store.getters.getCurrentEgpId
    this.nextStudyId = await getNextStudyId(egpId)

    if (templateId && egpId) {
      let responseTemplate = await axios.get(
        '/template/getTemplateAntworten/' + egpId + '/' + templateId,
      )

      if (responseTemplate.data && typeof responseTemplate.data === 'object') {
        this.rows = Object.values(responseTemplate.data).map((row) => {
          const newRow = {}
          this.columns.forEach((column) => {
            if (column.name !== 'delete') {
              newRow[column.name] = row[column.name] || null
            }
          })
          return newRow
        })
      } else {
        this.rows = []
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
