<template>
  <div class="editable-cell-container">
    <span v-if="localContent" class="cell-content">{{ localContent }}</span>
    <q-popup-edit
      :modelValue="localContent"
      @update:modelValue="handleModelValueUpdate"
      :title="columnTitle"
      :isMandatory="isMandatory"
      buttons
      persistent
      v-slot="scope"
    >
      <q-input
        v-model="scope.value"
        type="number"
        dense
        autofocus
        :rules="[(val) => val > 0 || 'Please enter a value greater than 0']"
      />
    </q-popup-edit>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, null],
      default: null,
    },
    columnTitle: {
      type: String,
      required: true,
    },
    isMandatory: {
      type: Boolean,
    },
  },
  emits: ['valueUpdated'],
  data() {
    return {
      localContent: this.modelValue,
    }
  },
  watch: {
    modelValue(newValue) {
      this.localContent = newValue
    },
  },
  methods: {
    handleModelValueUpdate(newValue) {
      this.localContent = newValue
      if (newValue > 0) {
        this.$emit('update:modelValue', newValue)
      }
    },
  },
}
</script>

<style scoped>
.editable-cell-container {
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  width: 150px; /* Set the desired width */
  height: 40px; /* Set the desired height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
