<template>
  <div class="dropdown-container">
    <DropdownCellView
      v-model="selectedMonth"
      :options="monthOptions"
      :isMandatory="isMandatory"
      @update:modelValue="handleMonthUpdate"
      class="dropdown-item"
    />
    <DropdownCellView
      v-model="selectedYear"
      :options="yearOptions"
      @update:modelValue="handleYearUpdate"
      class="dropdown-item"
    />
  </div>
</template>

<script>
import DropdownCellView from './GipamDropdownCellView.vue'
export default {
  components: {
    DropdownCellView,
  },
  props: {
    content: {
      type: [Array, String, Object],
      default: '',
    },
    isMandatory: {
      type: Boolean,
    },
  },
  data() {
    const currentYear = new Date().getFullYear()
    const yearRange = Array.from({ length: 21 }, (_, i) => currentYear - 5 + i)

    return {
      selectedMonth: null,
      selectedYear: null,
      monthOptions: [
        { label: 'Jan' },
        { label: 'Feb' },
        { label: 'Mar' },
        { label: 'Apr' },
        { label: 'May' },
        { label: 'Jun' },
        { label: 'Jul' },
        { label: 'Aug' },
        { label: 'Sep' },
        { label: 'Oct' },
        { label: 'Nov' },
        { label: 'Dec' },
      ],
      yearOptions: yearRange.map((year) => ({ label: year.toString() })),
    }
  },
  mounted() {
    this.setInitialValues(this.content)
    this.emitSelectedValue()
  },
  methods: {
    handleMonthUpdate(value) {
      this.selectedMonth = value
      this.emitSelectedValue()
    },
    handleYearUpdate(value) {
      this.selectedYear = value
      this.emitSelectedValue()
    },
    emitSelectedValue() {
      const { selectedMonth, selectedYear } = this
      const value =
        selectedMonth || selectedYear
          ? `${selectedYear}/${selectedMonth.toString()}`
          : null
      this.$emit('update:modelValue', value)
    },
    setInitialValues(content) {
      if (content) {
        if (typeof content.value === 'string' && content.value.includes('/')) {
          const [year, month] = content.value.split('/')
          this.selectedYear = parseInt(year, 10) || null
          this.selectedMonth = month || null
        } else {
          this.selectedMonth = null
          this.selectedYear = null
        }
      } else {
        this.selectedMonth = null
        this.selectedYear = null
      }
    },
  },
}
</script>

<style scoped>
.dropdown-container {
  display: flex;
}

.dropdown-item {
  flex: 1;
  max-width: 150px; /* Adjust the maximum width as needed */
}
</style>
