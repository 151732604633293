<template>
  <div class="editable-cell-container">
    <q-select v-model="selectedOption" :options="options" :modelValue="modelValue" :isMandatory="isMandatory"
      @update:model-value="handleModelValueUpdate" emit-value map-options new-value-mode="add-unique" multiple
      style="width: max-content; max-width: 90vw; min-width: 200px">
      <template v-slot:selected>
        <div style="display: flex; align-items: center">
          <span v-if="isMandatory && selectedOption.length === 0" class="text-negative">
            Select an option
          </span>
          <div v-else style="display: flex; flex-direction: column; margin-left: 0.5rem">
            <div v-for="(option, index) in selectedOption" :key="index">
              <div class="row items-center">
                {{ option }}
                <q-btn flat dense color="primary" icon="edit" @click="openEditModal(option, $event)" />
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:no-option>
        <q-item>
          <q-item-section class="text-grey"> No results </q-item-section>
        </q-item>
      </template>
    </q-select>
    <q-dialog v-model="showEditModal">
      <q-card>
        <q-card-section>
          <q-input v-model="editedLabel" label="Edit Option" />
        </q-card-section>
        <q-card-actions>
          <q-btn color="primary" @click="saveEditedOption">Save</q-btn>
          <q-btn color="negative" @click="closeEditModal">Cancel</q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      default: () => [],
    },
    isMandatory: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedOption: [],
      showEditModal: false,
      editedLabel: '',
      editedOption: null,
    }
  },
  watch: {
    modelValue: {
      handler(newValue) {
        if (Array.isArray(newValue)) {
          if (newValue[0] !== 'Other:') {
            this.selectedOption = newValue
          }
        } else if (typeof newValue === 'string') {
          // Split the string on '*' to get an array
          const targetPopulationParts = newValue.split('*')
          const updatedTargetPopulationParts = targetPopulationParts.map(
            (part) => part.replace(/###COMMA###/g, ','),
          )
          this.selectedOption = updatedTargetPopulationParts
        } else if (newValue !== null && newValue !== undefined) {
          this.selectedOption = [newValue.toString()]
        } else {
          this.selectedOption = []
        }
      },
    },
    options: {
      handler(newOptions) {
        if (newOptions.length > 0 && this.modelValue.length === 0) {
          const lastOption = newOptions[newOptions.length - 1]
          this.$emit('update:modelValue', [lastOption.value])
        }
      },
      deep: true,
    },
  },
  computed: {
    isOtherOptionSelected() {
      if (!Array.isArray(this.options) || this.options.length === 0) {
        return false
      }
      const otherOption = this.options.find((option) => option.value === null)
      return otherOption && this.selectedOption === otherOption.value
    },
  },
  mounted() {
    // Check if this.modelValue is not null or undefined
    if (typeof this.modelValue === 'string') {
      // Split the value by '*' to get the parts
      const targetPopulationParts = this.modelValue.split('*')
      // Map over the parts and replace '###COMMA###' with ','
      const updatedTargetPopulationParts = targetPopulationParts.map((part) =>
        part.replace(/###COMMA###/g, ','),
      )
      const modelValueArray = updatedTargetPopulationParts
      this.$emit('update:modelValue', modelValueArray)

      // Create a Set to remove duplicates
      const uniqueOptions = new Set([
        ...this.options.map((option) => option.value),
        ...modelValueArray,
      ])

      // Convert the Set back to an array of objects
      const updatedOptions = Array.from(uniqueOptions).map((value) => ({
        label: value,
        value,
      }))
      this.$emit('update:options', updatedOptions)
    }
    else if (Array.isArray(this.modelValue)) {
      // Falls modelValue bereits ein Array ist, direkt verwenden
      this.selectedOption = this.modelValue
    } else {
      console.warn('this.modelValue is null or undefined')
      // Handle the case when this.modelValue is null or undefined
      // For example, you could emit an empty array
      this.$emit('update:modelValue', [])
    }
  },
  methods: {
    handleModelValueUpdate(value) {
      let selectedValues = []

      // Check if the value is an array
      if (Array.isArray(value)) {
        selectedValues = value.map((option) => {
          if (typeof option === 'object' && option !== null) {
            // Handle proxy object
            return option.value
          } else {
            // Handle string value (new option)
            return option
          }
        })
      } else if (typeof value === 'string') {
        // Handle string value (edited option)
        selectedValues = [value]
      }

      // Check if 'Global' or 'Any Treatment' is present in selectedValues
      const hasGlobal = selectedValues.includes('Global')
      const hasAnyTreatment = selectedValues.includes('Any Treatment')
      const hasNone = selectedValues.includes('None')

      if (hasGlobal) {
        this.selectedOption = ['Global']
      } else if (hasNone) {
        this.selectedOption = ['None']
      } else if (hasAnyTreatment) {
        this.selectedOption = ['Any Treatment']
        selectedValues = ['Any Treatment']
        this.$emit('update:modelValue', selectedValues)
      } else if (selectedValues.length === 0) {
        // Emit an empty array if no values are selected
        this.$emit('update:modelValue', [])
        this.selectedOption = []
      } else {
        this.selectedOption = selectedValues
        this.$emit('update:modelValue', selectedValues)
      }
    },
    openEditModal(option, event) {
      event.stopPropagation()
      const originalOption = this.modelValue.find((opt) => opt === option)
      this.editedLabel = originalOption
      this.editedOption = originalOption
      this.showEditModal = true
    },
    saveEditedOption() {
      if (this.editedOption) {
        const optionIndex = this.modelValue.indexOf(this.editedOption)
        if (optionIndex !== -1) {
          // Remove the existing option from the modelValue and selectedOption arrays
          const updatedModelValue = [
            ...this.modelValue.slice(0, optionIndex),
            ...this.modelValue.slice(optionIndex + 1),
          ]
          this.$emit('update:modelValue', updatedModelValue)

          const updatedSelectedOption = this.selectedOption.filter(
            (option) => option !== this.editedOption,
          )
          this.selectedOption = updatedSelectedOption

          // Add the edited option as a new option
          const newModelValue = [...updatedModelValue, this.editedLabel]
          this.$emit('update:modelValue', newModelValue)

          const newSelectedOption = [...updatedSelectedOption, this.editedLabel]
          this.selectedOption = newSelectedOption

          // Emit the update:options event with the new option
          this.$emit('update:options', [
            ...this.options,
            { label: this.editedLabel, value: this.editedLabel },
          ])
        } else {
          // Handle the case when the edited option is a new option
          this.editedOption = this.editedLabel
          const optionIndex = this.modelValue.indexOf('')
          if (optionIndex !== -1) {
            const updatedModelValue = [...this.modelValue]
            updatedModelValue.splice(optionIndex, 1, this.editedLabel)
            this.$emit('update:modelValue', updatedModelValue)

            const updatedSelectedOption = this.selectedOption.map((option) => {
              if (option === this.editedOption) {
                return this.editedLabel
              }
              return option
            })
            this.selectedOption = updatedSelectedOption

            // Emit the update:options event with the new option
            this.$emit('update:options', [
              ...this.options,
              { label: this.editedLabel, value: this.editedLabel },
            ])
          }
        }
      }
      this.closeEditModal()
    },
    closeEditModal() {
      this.showEditModal = false
      this.editedLabel = ''
      this.editedOption = null
    },
  },
}
</script>

<style scoped>
.editable-cell-container {
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cell-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
