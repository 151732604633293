// QuestionService.js
import {
  getNextQuestionId,
  getPreviousQuestion,
  getQuestionnaire,
  saveAnswer,
} from '@/service/Api.ts'
import store from '@/store.js'

export default {
  async getCurrentQuestion(frageId, egpId) {
    try {
      return await getQuestionnaire(frageId, egpId)
    } catch (err) {
      console.error('Error getting current question:', err)
      throw err
    }
  },

  async saveAnswer(givenAnswer) {
    await saveAnswer(givenAnswer)
  },

  async getNextQuestion(currentQuestion, currentAnswerIds) {
    const egpId = store.getters.getCurrentEgpId
    const givenAnswer = {
      antwortId: currentAnswerIds[currentAnswerIds.length - 1],
      egpId,
      frageId: currentQuestion.id,
    }
    const nextQuestionId = await getNextQuestionId(givenAnswer)
    return nextQuestionId
  },

  async getPreviousQuestion(currentQuestion) {
    const dto = {
      egpId: store.getters.getCurrentEgpId,
      questionId: currentQuestion.id,
    }
    const previousQuestionId = await getPreviousQuestion(dto)
    return previousQuestionId
  },
}
