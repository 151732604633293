<template>
  <span class="static-cell">{{ content }}</span>
</template>

<script>
export default {
  props: {
    content: {
      type: [String, Number, Object, null],
      default: '',
    },
    customClass: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>
.static-cell {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  padding: 10px;
  word-wrap: break-word;
}
</style>
