// columnDefinitions.ts
const options = [
  { label: 'On track', value: 'On track' },
  { label: 'Slight delay', value: 'Slight delay' },
  { label: 'At risk', value: 'At risk' },
]

const columns = [
  { name: 'studyId', align: 'center', label: 'Study Id', field: 'studyId' },
  {
    name: 'category',
    align: 'left',
    label: 'Category',
    field: 'category',
  },
  {
    name: 'studyObjectives',
    align: 'left',
    label: 'Main study objective(s)',
    field: 'studyObjectives',
    type: 'standard',
  },
  {
    name: 'targetCountries',
    align: 'left',
    label: 'Target countries',
    field: 'targetCountries',
    type: 'standard',
  },
  {
    name: 'studyStarted',
    align: 'left',
    label: 'Has this study already been started?',
    field: 'studyStarted',
    type: 'standard',
  },
  {
    name: 'startDate',
    align: 'left',
    label: 'Intended start date',
    field: 'startDate',
  },
  {
    name: 'finalizationDate',
    align: 'left',
    label: 'Intended finalization date / Revised finalization date',
    field: 'finalizationDate',
    type: 'monthYear',
  },
  {
    name: 'currentStatus',
    align: 'left',
    label: 'Current status',
    field: 'currentStatus',
    type: 'dropdown',
    options,
  },
  {
    name: 'comments',
    align: 'left',
    label: 'Comments/other specifics',
    field: 'comments',
    type: 'editable',
  },
  {
    name: 'linkTemplate',
    align: 'left',
    label: 'Link to study document(s)',
    field: 'linkTemplate',
    type: 'link',
  },
]

export { columns, options }
