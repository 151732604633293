import axios from 'axios'
const API_ENDPOINTS = {
  GET_TEMPLATE_BY_ANSWER_ID: '/template/getTemplateByAnswerId/',
  SAVE_ANSWER: '/questionnaire/saveAnswer',
  GET_QUESTIONNAIRE: '/questionnaire/getQuestionnaire/',
  GET_PREVIOUS_QUESTION: '/questionnaire/getPreviousQuestion',
  GET_NEXT_QUESTION_ID: '/questionnaire/getNextQuestionId/',
  SAVE_TEMPLATE: '/template/save',
  GET_NEXT_STUDY_ID: '/template/getNextStudyId',
}

export async function getTemplateByAnswerId(id) {
  const response = await axios.get(API_ENDPOINTS.GET_TEMPLATE_BY_ANSWER_ID + id)
  return response.data
}

export async function saveAnswer(answer) {
  const response = await axios.post(API_ENDPOINTS.SAVE_ANSWER, answer)
  return response.data
}

export async function getQuestionnaire(frageId, egpId) {
  const response = await axios.get(
    API_ENDPOINTS.GET_QUESTIONNAIRE + frageId + '/' + egpId,
  )
  return response.data
}

export async function getPreviousQuestion(dto) {
  const response = await axios.post(API_ENDPOINTS.GET_PREVIOUS_QUESTION, dto)
  return response.data
}

export async function getNextQuestionId(beantwortung) {
  const response = await axios.post(
    API_ENDPOINTS.GET_NEXT_QUESTION_ID,
    beantwortung,
  )
  return response.data
}

export async function saveTemplate(dto) {
  const response = await axios.post(API_ENDPOINTS.SAVE_TEMPLATE, dto)
  return response.data
}

export async function getNextStudyId(egpId) {
  const response = await axios.get(
    `${API_ENDPOINTS.GET_NEXT_STUDY_ID}/${egpId}`,
  )
  return response.data
}
